import { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../backend/configConexaoGeral.js'

const AlterarSituacaoModal = ({
  show,
  onHide,
  onSave,
  situacaoAtual,
  todasAsSituacoes,
  situacaoAnterior,
  funcionariosPresentes,
  obterSituacaoAnterior,
  selectedFuncionario,
  onDelete,
  onCreate
}) => {
  const [novaSituacao, setNovaSituacao] = useState('')
  const [opcoesDisponiveis, setOpcoesDisponiveis] = useState([])
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const situacaoAnt = obterSituacaoAnterior(
    funcionariosPresentes,
    selectedFuncionario?._id,
    selectedFuncionario?.data
  )

  const filtrarSituacoesDisponiveis = (todasAsSituacoes, situacaoAtual, situacaoAnt) => {
    if (!todasAsSituacoes?.length || !situacaoAtual) return []

    const situacaoAnterior = situacaoAnt?.situacao

    // Executa o filtro de situações disponíveis
    return todasAsSituacoes.filter(situacao => {
      const situacaoLower = situacao.toLowerCase()
      const situacaoAtualLower = situacaoAtual.toLowerCase()
      const situacaoAnteriorLower = situacaoAnterior?.toLowerCase()
      // Regra 1: Não pode ter a mesma situação atual
      if (situacao === situacaoAtual) return false
      // Regra 2: Não pode ser igual à situação anterior, se houver
      if (situacao === situacaoAnterior) return false
      // Regra 3: Se a situação atual contiver "entrou", nenhuma opção é válida
      if (situacaoAtualLower.includes('entrou')) {
        return false
      }
      // Regra 4: Se a situação anterior contiver "entrou", o filtro remove situações contendo "voltou"
      if (situacaoAnteriorLower?.includes('entrou') && situacaoLower.includes('voltou')) {
        return false
      }
      // Regra 5: Nunca pode ter uma situação que contenha "entrou" em todasAsSituacoes
      if (situacaoLower.includes('entrou')) {
        return false
      }
      // Regra 6: Se a situação anterior contiver "saiu", o filtro remove situações contendo "saiu"
      if (situacaoAnteriorLower?.includes('saiu') && situacaoLower.includes('saiu')) {
        return false
      }
      // Regra 7: Se a situação anterior contiver "saiu", a proxima situcao so pode ser referente a anterior , por isso nao pode deixar alterar a situacao
      if (situacaoAnteriorLower?.includes('saiu')) {
        return false
      }
      // Regra 8: Se a situação anterior contiver "voltou", a proxima situcao nao pode ser uma situacao que contenha voltou
      if (situacaoAnteriorLower?.includes('voltou') && situacaoLower.includes('voltou')) {
        return false
      }
      // Regra 9: Se a situação anterior contiver "voltou do café da manhã", a proxima situcao so pode conter "saiu para o café da manhã"
      if (
        situacaoAnteriorLower?.includes('voltou do café da manhã') &&
        situacaoLower.includes('saiu para o café da manhã')
      ) {
        return false
      }
      // Regra 10: Se a situação anterior contiver "saiu", a proxima situcao nao pode ser alterada
      if (situacaoAnteriorLower?.includes('saiu')) {
        return false
      }
      // Regra 11: Se a anterior  contiver "voltou do almoço" entao a siuacao atual so pode ser ou "saiu para o café da tarde" ou "foi embora"
      if (
        situacaoAnteriorLower?.includes('voltou do almoço') &&
        !situacaoLower.includes('saiu para o café da tarde') &&
        !situacaoLower.includes('foi embora')
      ) {
        return false
      }
      // Regra 12: Se a anterior contiver "entrou na empresa" entao a situacao atual nao pode contar "voltou"
      if (
        situacaoAnteriorLower?.includes('entrou na empresa') &&
        situacaoLower.includes('voltou')
      ) {
        return false
      }
      return true
    })
  }

  useEffect(() => {
    if (todasAsSituacoes?.length && situacaoAtual) {
      const filtroSituacoes = filtrarSituacoesDisponiveis(
        todasAsSituacoes,
        situacaoAtual,
        situacaoAnt
      )
      setOpcoesDisponiveis(filtroSituacoes)
    }
  }, [situacaoAtual, todasAsSituacoes, situacaoAnterior, situacaoAnt])

  const handleSave = async () => {
    try {
      const url = `${config.host}/funcionariosAtivos`
      const dados = {
        requisicao: 'registrarPonto',
        situacao: novaSituacao,
        funcionarioId: selectedFuncionario?._id,
        ativo: true,
        data: new Date()
      }
      const response = await fetchData(url, dados)
      console.log('response em handleSave:', response)
      if (response?.registro) {
        console.log('Chamando onCreate com:', response.registro)
        onCreate(response.registro) // Notifica o componente pai
      }

      onSave(novaSituacao)
      onHide()
    } catch (error) {
      console.error('Erro ao salvar situação:', error)
    }
  }

  const handleDelete = async () => {
    try {
      const url = `${config.host}/funcionariosAtivos`
      const dados = {
        requisicao: 'excluirSituacao',
        _id: selectedFuncionario?.situacaoId // Confirma o uso do ID da situação
      }
      console.log('selectedFuncionario:', selectedFuncionario) // Log para verificação
      console.log('Dados enviados para exclusão:', dados) // Log para verificação
      await fetchData(url, dados)

      if (onDelete) {
        onDelete(selectedFuncionario) // Notifica o componente pai
      }

      setShowConfirmDelete(false)
      onHide()
    } catch (error) {
      console.error('Erro ao excluir situação:', error)
    }
  }

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Alterar Situação: {situacaoAtual}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="novaSituacao">
              <Form.Label>Selecione a Nova Situação</Form.Label>
              <Form.Control
                as="select"
                value={novaSituacao}
                onChange={e => setNovaSituacao(e.target.value)}
              >
                <option value="">Selecione uma situação</option>
                {opcoesDisponiveis.map((situacao, index) => (
                  <option key={index} value={situacao}>
                    {situacao}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={() => setShowConfirmDelete(true)}>
            Excluir
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={!novaSituacao}>
            Salvar Alterações
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Confirmação */}
      <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que deseja excluir a situação {situacaoAtual} ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

AlterarSituacaoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  situacaoAtual: PropTypes.string.isRequired,
  todasAsSituacoes: PropTypes.array.isRequired,
  situacaoAnterior: PropTypes.string,
  funcionariosPresentes: PropTypes.array.isRequired,
  obterSituacaoAnterior: PropTypes.func.isRequired,
  selectedFuncionario: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
}

export default AlterarSituacaoModal
