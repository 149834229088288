// CorteMenu.js

import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PrivateMenuItem from '../../../src/components/PrivateMenuItem.jsx'

const CorteMenu = () => {
  return (
    <PrivateMenuItem tela="Corte">
      <NavDropdown title="Corte" id="corte-dropdown">
        <NavDropdown.Item as={Link} to="/corte-cadastro-cliente" className="nav-dropdown-item">
          Cadastro Cliente
        </NavDropdown.Item>
        <NavDropdown.Item
          as={Link}
          to="/corte-pesquisa-e-edicao-cliente"
          className="nav-dropdown-item"
        >
          Pesquisa e Edição de Cliente
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/corte-cadastro-corte" className="nav-dropdown-item">
          Cadastro Corte
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/corte-editar-corte" className="nav-dropdown-item">
          Editar Corte
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/finalizacao-de-cortes" className="nav-dropdown-item">
          Finalização de Cortes
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/imprimir-etiqueta-de-corte" className="nav-dropdown-item">
          Imprimir Etiqueta de Corte
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/cadastro-tecido" className="nav-dropdown-item">
          Cadastro Tecido
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/configurar-valor-embalagem" className="nav-dropdown-item">
          Configurar Valor Embalagem
        </NavDropdown.Item>
      </NavDropdown>
    </PrivateMenuItem>
  )
}

export default CorteMenu
