// Gerenciamento de Gerente de Operações

import { useState, useEffect, useContext } from 'react'
import { Container, Form, Button, Table, Alert, Modal } from 'react-bootstrap'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { fetchData } from '../../../../backend/model.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { config } from '../../../../../src/backend/configConexaoGeral'
import { CustomTooltip } from '../../../../../src/components/pages/Home/NavbarModelagemcompleta.jsx'
import CadastroFacial from '../../../../../src/components/pages/RELOGIODEPONTO/FuncionariosAtivos/CadastroFacial/cadastro-facial.jsx'
const GerenteOperacoes = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [funcionarios, setFuncionarios] = useState([])
  const [gerentes, setGerentes] = useState([])
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')
  const [mostrarTooltip, setMostrarTooltip] = useState(false)
  const [showFaceModal, setShowFaceModal] = useState(false)
  const [selectedFuncionarioId, setSelectedFuncionarioId] = useState(null)
  const [checkFace, setCheckFace] = useState(false)
  const [donoLogado, setDonoLogado] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [idParaRemover, setIdParaRemover] = useState(null)
  const [faceCadastrada, setFaceCadastrada] = useState(false)
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 3000) // Fecha após 3 segundos
      return () => clearTimeout(timer)
    }
  }, [showAlert])

  useEffect(() => {
    const fetchFuncionarios = async () => {
      try {
        const url = `${config.host}/gerenciarSetores`
        const dados = { tenantId, rota_base: RotaBase, requisicao: 'listarFuncionarios' }
        const response = await fetchData(url, dados)

        setFuncionarios(response || [])
      } catch (error) {
        console.error('Erro ao buscar funcionários:', error)
      }
    }

    const fetchGerentes = async () => {
      try {
        const url = `${config.host}/gerenciarGerentesOperacao`
        const response = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarGerentesOperacoes'
        })
        setGerentes(response || [])
      } catch (error) {
        console.error('Erro ao buscar gerentes:', error)
      }
    }

    fetchFuncionarios()
    fetchGerentes()
  }, [RotaBase, tenantId])

  useEffect(() => {
    if (checkFace) {
      const verificarLeituraFacial = async funcionarioId => {
        const url = `${config.host}/funcionariosAtivos`
        const response = await fetchData(url, {
          tenantId,
          requisicao: 'verificarLeituraFacial',
          funcionarioId
        })

        if (!response.exists) {
          setSelectedFuncionarioId(funcionarioId)
          setShowFaceModal(true)
          // Não prossegue para adicionar gerente agora, espera o cadastro facial.
        } else {
          // Se a face já existe, adiciona o gerente diretamente.
          try {
            const urlGerente = `${config.host}/gerenciarGerentesOperacao`
            const responseGerente = await fetchData(urlGerente, {
              tenantId,
              rota_base: RotaBase,
              funcionarioId: funcionarioSelecionado,
              requisicao: 'criarGerenteOperacoes'
            })
            setAlertVariant('success')
            setAlertMessage('Gerente de Operações adicionado com sucesso!')
            setShowAlert(true)
            // Atualiza o array de gerentes com o novo gerente
            setGerentes(prev => [...prev, responseGerente.gerente])
            setFuncionarioSelecionado('')
          } catch (error) {
            console.error('Erro ao adicionar Gerente de Operações:', error)
            const message =
              error?.response?.data?.message ||
              error?.message ||
              'Erro ao adicionar Gerente de Operações.'
            setAlertVariant('danger')
            setAlertMessage(message)
            setShowAlert(true)
          }
        }
      }

      verificarLeituraFacial(funcionarioSelecionado)
    }
    setCheckFace(false)
  }, [checkFace, funcionarioSelecionado, tenantId, RotaBase, funcionarioSelecionado])

  const solicitarConfirmacaoRemocao = id => {
    setIdParaRemover(id)
    setShowConfirm(true)
  }
  // Ações de confirmação e cancelamento
  const confirmarRemocao = () => {
    if (idParaRemover) {
      handleRemoverGerente(idParaRemover)
    }
    setShowConfirm(false)
    setIdParaRemover(null)
  }

  const cancelarRemocao = () => {
    setShowConfirm(false)
    setIdParaRemover(null)
  }
  const handleRemoverGerente = async id => {
    try {
      const url = `${config.host}/gerenciarGerentesOperacao`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        funcionarioId: id,
        requisicao: 'removerGerenteOperacoes'
      })

      setGerentes(prev => prev.filter(g => g.funcionarioId._id !== id))
      setAlertVariant('success')
      setAlertMessage('Gerente de Operações removido com sucesso!')
      setShowAlert(true)
    } catch (error) {
      console.error('Erro ao remover Gerente de Operações:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao remover Gerente de Operações.')
      setShowAlert(true)
    }
  }

  return (
    <Container>
      <h2 className="mt-4">Gerenciamento de Gerentes de Operações</h2>
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
      <Form.Group className="my-3">
        <Form.Label>Selecionar Funcionário</Form.Label>
        <Form.Control
          as="select"
          value={funcionarioSelecionado}
          onChange={e => setFuncionarioSelecionado(e.target.value)}
        >
          <option value="">Selecione um funcionário</option>
          {funcionarios.map(func => (
            <option key={func._id} value={func._id}>
              {func.Nome}
            </option>
          ))}
        </Form.Control>
        <Button
          onClick={() => {
            setMostrarTooltip(true)
          }}
          disabled={!funcionarioSelecionado}
        >
          Adicionar como Gerente
        </Button>
      </Form.Group>

      {mostrarTooltip && (
        <CustomTooltip
          mode="validarSenha"
          fields={['username', 'password']}
          setDonoLogado={setDonoLogado}
          onCancel={() => setMostrarTooltip(false)}
          manualTrigger={true}
          show={mostrarTooltip} // Passa o estado manual
          onClose={() => setMostrarTooltip(false)} // Fecha manualmente
          setCheckFace={setCheckFace}
        />
      )}
      <h4>Gerentes de Operações</h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {gerentes.map(gerente => (
            <tr key={gerente._id}>
              <td>{gerente.funcionarioId?.Nome || '---'}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => solicitarConfirmacaoRemocao(gerente.funcionarioId?._id)}
                >
                  Remover
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Modal de Confirmação */}
      <ConfirmModal
        show={showConfirm}
        message="Deseja realmente remover este Gerente de Operações?"
        onConfirm={confirmarRemocao}
        onCancel={cancelarRemocao}
      />
      {/* Modal para Cadastro Facial */}
      <Modal show={showFaceModal} onHide={() => setShowFaceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro Facial</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CadastroFacial
            isForGerenteOperacoes={true}
            funcionarioNome={funcionarios.find(f => f._id === selectedFuncionarioId)?.Nome || ''}
            funcionarioId={selectedFuncionarioId}
            onClose={() => setShowFaceModal(false)}
            setFaceCadastrada={setFaceCadastrada}
            onFaceCadastrada={async () => {
              // Após o cadastro facial, adicione o gerente
              try {
                const url = `${config.host}/gerenciarGerentesOperacao`
                const response = await fetchData(url, {
                  tenantId,
                  rota_base: RotaBase,
                  funcionarioId: funcionarioSelecionado,
                  requisicao: 'criarGerenteOperacoes'
                })
                setAlertVariant('success')
                setAlertMessage('Gerente de Operações adicionado com sucesso!')
                setShowAlert(true)
                setGerentes(prev => [...prev, response.gerente])
                setFuncionarioSelecionado('')
              } catch (error) {
                console.error('Erro ao adicionar Gerente de Operações:', error)
                setAlertVariant('danger')
                setAlertMessage('Erro ao adicionar Gerente de Operações.')
                setShowAlert(true)
              } finally {
                setDonoLogado(false)
                setShowFaceModal(false) // Feche o modal após o cadastro e adição
              }
            }}
          />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default GerenteOperacoes

export const ConfirmModal = ({ show, message, onConfirm, onCancel }) => (
  <Modal show={show} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>Confirmação</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{message}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onCancel}>
        Não
      </Button>
      <Button variant="primary" onClick={onConfirm}>
        Sim
      </Button>
    </Modal.Footer>
  </Modal>
)
