// src/pages/ResetarSenha.jsx
import { useState, useContext } from 'react'
import { config } from '../../../../../src/backend/configConexaoGeral'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { fetchData } from '../../../../../src/backend/model.js'
import { AuthContext } from '../../../../../src/context/AuthContext'
import logoTopoModelagemCompleta from '../../../../../src/components/pages/Usuarios/login/logo_topo.jpg' // Ajuste o caminho conforme necessário
import logoTopoModaNegocios360 from '../../../../../src/components/pages/Usuarios/login/4.png'
import { ShowAlertGeneric } from '../../../../../src/utils/form-utils.jsx'
import bcrypt from 'bcryptjs'
const ResetarSenha = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const [novaSenha, setNovaSenha] = useState('')
  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [erro, setErro] = useState('')
  const [carregando, setCarregando] = useState(false)
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')
  const { RotaBase } = useContext(AuthContext)
  const hostname = window.location.hostname

  const isModaNegocios360 = hostname && hostname.includes('moda.negocios360')
  const logoTopo = isModaNegocios360 ? logoTopoModaNegocios360 : logoTopoModelagemCompleta

  const hashPassword = async senha => {
    const saltRounds = 10
    const salt = await bcrypt.genSalt(saltRounds)
    return bcrypt.hash(senha, salt)
  }
  const handleSubmit = async e => {
    e.preventDefault()

    // Validação básica das senhas
    if (novaSenha !== confirmarSenha) {
      setErro('As senhas não coincidem.')
      return
    }

    if (novaSenha.length < 6) {
      setErro('A senha deve ter pelo menos 6 caracteres.')
      return
    }

    setCarregando(true)
    setErro('')
    setMensagem('')
    setShowAlertMessage(false)

    try {
      // Encriptar a senha antes de enviar
      const senhaEncriptada = await hashPassword(novaSenha)

      const url = `${config.host}/tenants`

      const dados = {
        token,
        novaSenha: senhaEncriptada,
        rota_base: RotaBase,
        requisicao: 'alterar_senha'
      }

      const response = await fetchData(url, dados)
      setMensagem(response.message || 'Senha redefinida com sucesso.')

      setAlertVariant('success')
      setAlertMessage(response.message || 'Senha redefinida com sucesso.')
      setShowAlertMessage(true)

      setTimeout(() => {
        navigate('/login')
      }, 3000)
    } catch (err) {
      setErro(err.message || 'Erro ao resetar a senha.')
      setAlertVariant('danger')
      setAlertMessage(err.message || 'Erro ao resetar a senha.')
      setShowAlertMessage(true)
    } finally {
      setCarregando(false)
    }
  }

  return (
    <div className="resetar-senha-container">
      <header className="resetar-senha-header">
        <div className="resetar-senha-header-content">
          <Link to="/">
            <img src={logoTopo} alt="Logo" className="resetar-senha-logo-topo" />
          </Link>
          <h1 className="resetar-senha-sistema-nome">
            {isModaNegocios360 ? 'Moda Negócios 360' : 'Modelagem Completa'}
          </h1>
        </div>
        <Link to="/fale-conosco" className="resetar-senha-contact-link">
          Fale conosco
        </Link>
      </header>

      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlertMessage}
        variant={alertVariant}
        timeOut={90000}
      />

      <h2>Redefinir Senha</h2>
      {mensagem && <p className="mensagem-sucesso">{mensagem}</p>}
      {erro && <p className="mensagem-erro">{erro}</p>}
      <form onSubmit={handleSubmit}>
        <div className="campo-formulario">
          <label htmlFor="novaSenha">Nova Senha:</label>
          <input
            type="password"
            id="novaSenha"
            value={novaSenha}
            onChange={e => setNovaSenha(e.target.value)}
            required
          />
        </div>
        <div className="campo-formulario">
          <label htmlFor="confirmarSenha">Confirmar Nova Senha:</label>
          <input
            type="password"
            id="confirmarSenha"
            value={confirmarSenha}
            onChange={e => setConfirmarSenha(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={carregando}>
          {carregando ? 'Redefinindo...' : 'Redefinir Senha'}
        </button>
      </form>
    </div>
  )
}

export default ResetarSenha
