// // src/components/pages/Payments/SelectPlan.jsx

/**
 * Nota Importante:
 * Sempre que um novo módulo for criado, é necessário realizar a seguinte alteração:
 *
 * 1. **Adicionar o nome do novo módulo ao array de módulos:**
 *    Atualize o objeto `modulos` localizado na função `modulosComAcessoGratuito` para incluir o novo módulo.
 *    Exemplo:
 *    ```javascript
 *    const modulos = {
 *        corte: 'corte',
 *        modelagem: 'modelagem',
 *        plotagem: 'plotagem',
 *        relogiodeponto: 'relogiodeponto',
 *        novomodulo: 'novomodulo' // Adicione aqui o novo módulo
 *    };
 *    ```
 *    - Certifique-se de que o nome do módulo seja consistente com o restante do sistema.
 *    - Essa alteração deve ser feita para garantir que o novo módulo seja reconhecido corretamente.
 */

import { useState, useContext, useEffect, useMemo } from 'react'
// import { initMercadoPago } from '@mercadopago/sdk-react'
import { config } from '../../../backend/configConexaoGeral'
import { fetchData } from '../../../backend/model'
import { AuthContext } from '../../../../src/context/AuthContext'
import { Tooltip } from 'react-tippy'
import { Link } from 'react-router-dom'
import { modulosAssinados, useFetchModules } from './SelectPlan.js'
// import 'react-tippy/dist/tippy.css' // Importando o CSS do react-tippy
import Modal from 'react-modal'

//import './SelectPlan.css' // Arquivo CSS para estilização

//initMercadoPago('TEST-05090b4e-3efd-4eca-8231-d32b94794285')
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

const SelectPlan = () => {
  const [selectedPlan, setSelectedPlan] = useState('mensal')
  const [selectedModules, setSelectedModules] = useState([])
  const [total, setTotal] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const {
    tenantId,
    RotaBase,
    nomeCliente,
    email,
    identificacaoFiscal,
    tipodocumento,
    acessoModulos
  } = useContext(AuthContext)
  const [subscribedModules, setSubscribedModules] = useState([])
  const [modulosComAcessoGratuito, setModulosComAcessoGratuito] = useState([])
  const [showFreePeriodButton, setShowFreePeriodButton] = useState(false)
  const [listaModulos, setListaModulos] = useState([])

  const [loadingModulos, setLoadingModulos] = useState(true) // Opcional: para gerenciar o estado de carregamento
  const [errorModulos, setErrorModulos] = useState(null) // Opcional: para gerenciar erros
  console.log('modulosComAcessoGratuito', modulosComAcessoGratuito)
  useEffect(() => {
    const fetchModules = async () => {
      const url = config.host + '/modulos' // Certifique-se de que este é o endpoint correto para listar módulos
      const dados = { rota_base: RotaBase, requisicao: 'listarModulos' }

      try {
        const response = await fetchData(url, dados)
        const servicos = response.servicos || []

        // Mapeie os serviços para o formato esperado
        const modulos = servicos.map(servico => ({
          nome: servico.nome,
          preco: servico.preco || 0 // Ajuste conforme a estrutura real dos dados
        }))
        console.log('modulos', modulos)
        setListaModulos(modulos)
        setLoadingModulos(false)
      } catch (error) {
        console.error('Erro ao carregar módulos:', error)
        setErrorModulos(error)
        setLoadingModulos(false)
      }
    }

    fetchModules()
  }, [tenantId, RotaBase])

  // const MODULOS_DISPONIVEIS = useMemo(
  //   () => [
  //     { nome: 'corte', preco: 0 },
  //     { nome: 'modelagem', preco: 0 },
  //     { nome: 'plotagem', preco: 0 }, // Adicione outros módulos conforme necessário
  //     { nome: 'relogiodePonto', preco: 0 } // Adicione outros módulos conforme necessário
  //   ],
  //   []
  // )

  let modulosDisponiveis = useFetchModules(tenantId, listaModulos).filter(
    modulo => modulo.nome !== 'Digitalizaçao'
  )

  //const [modulosDisponiveis, setModulosDisponiveis] = useState(MODULOS_DISPONIVEIS)
  // Inicia com os valores padrões mas será atualizado

  // useEffect(() => {
  //   const fetchModuleValues = async () => {
  //     const url = config.host + '/tenants'
  //     const dados = { tenantId, requisicao: 'ConsultarValoresModulos' }

  //     try {
  //       const response = await fetchData(url, dados)

  //       // Supondo que a resposta contenha um objeto `valoresModulos`
  //       const modulosValores = response || {}
  //       console.log('Valores dos Módulos:', modulosValores)

  //       // Atualiza o estado dos módulos disponíveis com os valores carregados do banco
  //       const modulosAtualizados = updateModulePrices(MODULOS_DISPONIVEIS, modulosValores)
  //       console.log('modulosAtualizados', modulosAtualizados)
  //       setModulosDisponiveis(modulosAtualizados)
  //     } catch (error) {
  //       console.error('Erro ao carregar os valores dos módulos:', error)
  //     }
  //   }

  //   fetchModuleValues()
  // }, [tenantId, MODULOS_DISPONIVEIS])

  // Função para buscar módulos assinados
  useEffect(() => {
    modulosAssinados(setSubscribedModules, tenantId, RotaBase) // Chama a função ao montar o componente
  }, [tenantId, RotaBase])

  // useEffect para chamar a função listarModulosComAcessoGratuito
  useEffect(() => {
    const fetchModulosComAcessoGratuito = async () => {
      const url = config.host + '/tenants' // Rota fornecida pelo usuário
      const dados = { tenantId, rota_base: RotaBase, requisicao: 'modulosComAcessoGratuito' } // Enviando tenantId como parte dos dados

      try {
        const response = await fetchData(url, dados)

        // Supondo que a resposta contenha um array de nomes de módulos assinados
        const ModulosAcessoGratuito = response.modules || []
        console.log('ModulosAcessoGratuito', ModulosAcessoGratuito)
        setModulosComAcessoGratuito(ModulosAcessoGratuito)
      } catch (error) {
        console.error('Erro ao carregar módulos assinados:', error)
      }
    }

    fetchModulosComAcessoGratuito()
  }, [tenantId])

  const handlePlanChange = event => {
    setSelectedPlan(event.target.value)
  }

  // Calcula a soma dos preços dos módulos selecionados
  const somaModulos = selectedModules.reduce((acc, moduloNome) => {
    const modulo = modulosDisponiveis.find(m => m.nome === moduloNome)
    return acc + (modulo ? modulo.preco : 0)
  }, 0)

  const updateModulePrices = (availableModules, moduleValues) => {
    return availableModules.map(modulo => {
      const moduloBanco = moduleValues.find(moduloValor => moduloValor.nome === modulo.nome)
      return {
        nome: modulo.nome,
        preco: moduloBanco ? moduloBanco.preco : modulo.preco
      }
    })
  }

  const calculatePlanPrice = planType => {
    let totalPlano = 0

    if (planType === 'mensal') {
      totalPlano = somaModulos
    } else if (planType === 'trimestral') {
      totalPlano = somaModulos * 3 * 0.9 // 10% de desconto
    } else if (planType === 'semestral') {
      totalPlano = somaModulos * 6 * 0.85 // 15% de desconto
    }

    return totalPlano.toFixed(2)
  }

  useEffect(() => {
    const calcularTotal = () => {
      let totalPlano = 0

      if (selectedPlan === 'mensal') {
        totalPlano = somaModulos
      } else if (selectedPlan === 'trimestral') {
        totalPlano = somaModulos * 3 * 0.9 // 10% de desconto
      } else if (selectedPlan === 'semestral') {
        totalPlano = somaModulos * 6 * 0.85 // 15% de desconto
      }

      setTotal(totalPlano)
    }

    calcularTotal()
  }, [selectedModules, selectedPlan, somaModulos])

  const handlePayment = async () => {
    if (selectedModules.length === 0) {
      alert('Por favor, selecione um ou mais módulos para assinar.')
      return
    }

    // Nova validação: impedir assinatura de módulos com valor zero
    const moduloComValorZero = selectedModules.some(moduloNome => {
      const modulo = modulosDisponiveis.find(m => m.nome === moduloNome)
      return modulo && modulo.preco === 0
    })

    if (moduloComValorZero) {
      alert(
        'Não é possível efetivar a assinatura enquanto o valor do módulo estiver zero. Por favor, aguarde o ajuste do valor da assinatura.'
      )
      return
    }

    // Cria os itens para cada módulo selecionado com base no plano escolhido
    let items = selectedModules.map(moduloNome => {
      const modulo = modulosDisponiveis.find(m => m.nome === moduloNome)
      let unit_price = 0
      if (selectedPlan === 'mensal') {
        unit_price = modulo.preco
      } else if (selectedPlan === 'trimestral') {
        unit_price = modulo.preco * 3 * 0.9 // 10% de desconto
      } else if (selectedPlan === 'semestral') {
        unit_price = modulo.preco * 6 * 0.85 // 15% de desconto
      }
      return {
        title: `Plano ${capitalize(modulo.nome)}`,
        quantity: 1,
        unit_price: parseFloat(unit_price.toFixed(2))
      }
    })

    const payer = {
      email: email,
      identification: {
        type: tipodocumento,
        number: identificacaoFiscal
      },
      first_name: nomeCliente
    }
    console.log('payer', payer)
    const url = config.host + '/assinaturas'
    const dados = {
      items: items,
      payer: payer,
      payment_methods: {
        installments: 1,
        default_installments: 1
      },
      requisicao: 'createPreference',
      clienteId: tenantId,
      selectedPlan: selectedPlan,
      modulos: selectedModules // Envia os módulos selecionados
    }

    try {
      const preference = await fetchData(url, dados)
      console.log('Preferência de pagamento criada:', preference)
      const { init_point } = preference
      window.open(init_point, '_blank')
    } catch (error) {
      console.error('Erro ao criar preferência de pagamento:', error)
    }
  }

  const handleFreePeriodStart = async () => {
    const url = config.host + '/tenants'
    const dados = {
      tenantId,
      requisicao: 'adicionarModulosPeriodoGratuito',
      novosModulos: selectedModules // Envia os módulos selecionados para o período gratuito
    }

    try {
      const response = await fetchData(url, dados)
      console.log('Período gratuito iniciado:', response)
      alert('Período gratuito iniciado com sucesso!')

      // Atualiza o estado dos módulos com acesso gratuito
      setModulosComAcessoGratuito(prevState => {
        const updatedState = { ...prevState }
        selectedModules.forEach(modulo => {
          updatedState[modulo] = { periodoGratuito: true, diasRestantes: 15 } // Ajuste os valores conforme necessário
        })
        return updatedState
      })
    } catch (error) {
      console.error('Erro ao iniciar período gratuito:', error)
      alert('Erro ao iniciar o período gratuito. Tente novamente mais tarde.')
    }
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const calculateEquivalentMonthly = planType => {
    if (planType === 'mensal') {
      return somaModulos
    } else if (planType === 'trimestral') {
      return (somaModulos * 3 * 0.9) / 3 // Simplifica para somaModulos * 0.9
    } else if (planType === 'semestral') {
      return (somaModulos * 6 * 0.85) / 6 // Simplifica para somaModulos * 0.85
    }
    return 0
  }
  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

  useEffect(() => {
    // Verifica se há algum módulo não assinado e sem período gratuito
    // const hasFreePeriodEligibleModule = selectedModules.some(moduloNome => {
    //   const isSubscribed = subscribedModules.includes(moduloNome)
    //   const isFreePeriod = modulosComAcessoGratuito[moduloNome]?.periodoGratuito
    //   return !isSubscribed && !isFreePeriod
    // })

    const hasFreePeriodEligibleModule = selectedModules.some(moduloNome => {
      const moduleKey = moduloNome.toLowerCase() // Padroniza o nome do módulo para minúsculas
      const isSubscribed = subscribedModules.map(name => name.toLowerCase()).includes(moduleKey) // Compara nomes em minúsculas
      const isFreePeriod = modulosComAcessoGratuito[moduleKey]?.periodoGratuito // Busca o módulo padronizado
      return !isSubscribed && !isFreePeriod
    })

    setShowFreePeriodButton(hasFreePeriodEligibleModule)
  }, [selectedModules, subscribedModules, modulosComAcessoGratuito])

  return (
    <div className="plan-container">
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <p>
          <strong>Não assinante?</strong> Aproveite 15 dias gratuitos com uso ilimitado dos
          serviços. Após esse período, você terá 10 usos gratuitos por mês.
        </p>
        <button onClick={openModal} style={{ marginLeft: '10px' }}>
          Saiba mais
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Informações sobre o uso gratuito"
      >
        <h2>Informações sobre o uso gratuito</h2>
        <p>
          Durante os primeiros 15 dias após o cadastro, você pode utilizar todos os serviços de
          forma ilimitada. Após esse período inicial, você terá direito a 10 usos gratuitos por mês.
          Para continuar usando os serviços sem limitações, considere assinar um dos nossos planos.
        </p>
        <button onClick={closeModal}>Fechar</button>
      </Modal>

      <h2 className="plan-title">Escolha seu Plano</h2>
      <div className="plan-options">
        <div className={`plan-card ${selectedPlan === 'mensal' ? 'selected' : ''}`}>
          <input
            type="radio"
            id="mensal"
            name="plan"
            value="mensal"
            checked={selectedPlan === 'mensal'}
            onChange={handlePlanChange}
          />
          <label htmlFor="mensal">
            <h3>Plano Mensal</h3>
            <p>{somaModulos > 0 ? `R$${calculatePlanPrice('mensal')}/mês` : 'R$0,00/mês'}</p>
            <p>Sem compromisso</p>
            <p></p>
          </label>
          <Tooltip
            title="Aproveite 15 dias gratuitos com uso ilimitado dos serviços. Após esse período, você terá 10 usos gratuitos por mês."
            position="top"
            trigger="mouseenter"
          >
            <span style={{ marginLeft: '10px', cursor: 'pointer' }}>ℹ️</span>
          </Tooltip>
        </div>
        <div className={`plan-card ${selectedPlan === 'trimestral' ? 'selected' : ''}`}>
          <input
            type="radio"
            id="trimestral"
            name="plan"
            value="trimestral"
            checked={selectedPlan === 'trimestral'}
            onChange={handlePlanChange}
          />
          <label htmlFor="trimestral">
            <h3>Plano Trimestral</h3>
            <p>
              {somaModulos > 0
                ? `R$${calculatePlanPrice('trimestral')}/trimestre`
                : 'R$0,00/trimestre'}
            </p>
            <p>
              {somaModulos > 0
                ? `(equivalente a R$${calculateEquivalentMonthly('trimestral').toFixed(2)}/mês)`
                : ''}
            </p>

            <p>Economize 10%</p>
          </label>
          <Tooltip
            title="Aproveite 15 dias gratuitos com uso ilimitado dos serviços. Após esse período, você terá 10 usos gratuitos por mês."
            position="top"
            trigger="mouseenter"
          >
            <span style={{ marginLeft: '10px', cursor: 'pointer' }}>ℹ️</span>
          </Tooltip>
        </div>
        <div className={`plan-card ${selectedPlan === 'semestral' ? 'selected' : ''}`}>
          <input
            type="radio"
            id="semestral"
            name="plan"
            value="semestral"
            checked={selectedPlan === 'semestral'}
            onChange={handlePlanChange}
          />
          <label htmlFor="semestral">
            <h3>Plano Semestral</h3>
            <p>
              {somaModulos > 0
                ? `R$${calculatePlanPrice('semestral')}/semestre`
                : 'R$0,00/semestre'}
            </p>
            <p>
              {somaModulos > 0
                ? `(equivalente a R$${calculateEquivalentMonthly('semestral').toFixed(2)}/mês)`
                : ''}
            </p>
            <p>Economize 15%</p>
          </label>
          <Tooltip
            title="Aproveite 15 dias gratuitos com uso ilimitado dos serviços. Após esse período, você terá 10 usos gratuitos por mês."
            position="top"
            trigger="mouseenter"
          >
            <span style={{ marginLeft: '10px', cursor: 'pointer' }}>ℹ️</span>
          </Tooltip>
        </div>
      </div>

      {/* Componente de Seleção de Módulos */}
      <ModuleSelection
        selectedModules={selectedModules}
        setSelectedModules={setSelectedModules}
        subscribedModules={subscribedModules}
        modulosComAcessoGratuito={modulosComAcessoGratuito}
        modulosDisponiveis={modulosDisponiveis}
      />

      {/* Seção de Total */}
      {/* <div className="total-section">
        <h3>Total: {`R$${total.toFixed(2)}`}</h3>
      </div> */}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ minWidth: '200px' }}>
          {' '}
          {/* Define um espaço fixo para o botão Assinar Plano */}
          <button onClick={handlePayment} className="plan-button" style={{ marginRight: '20px' }}>
            Assinar Plano
          </button>
        </div>
        {showFreePeriodButton && (
          <button
            style={{
              backgroundColor: '#ffd700',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer'
            }}
            onClick={handleFreePeriodStart}
          >
            Iniciar Período Gratuito
          </button>
        )}
      </div>
    </div>
  )
}

export default SelectPlan

// Componente para seleção de módulos

const ModuleSelection = ({
  selectedModules,
  setSelectedModules,
  subscribedModules,
  modulosComAcessoGratuito,
  modulosDisponiveis
}) => {
  // Função auxiliar para capitalizar o nome do módulo
  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

  const handleModuleChange = event => {
    const { value, checked } = event.target
    if (checked) {
      setSelectedModules(prev => [...prev, value])
    } else {
      setSelectedModules(prev => prev.filter(modulo => modulo !== value))
    }
  }

  // Função para redirecionar para a página de descrição do módulo
  // const goToModuleDescription = modulo => {
  //   window.location.href = `/module-description?module=${modulo}`
  // }

  return (
    <div className="modules-selection">
      <h3>Selecione os Módulos</h3>
      {console.log('selectedModules', selectedModules)}

      {modulosDisponiveis.map(modulo => {
        const isSubscribed = subscribedModules?.includes(modulo.nome.toLowerCase()) // Verifica se o módulo está assinado

        const isFreePeriod =
          !isSubscribed && modulosComAcessoGratuito[modulo.nome.toLowerCase()]?.periodoGratuito // Verifica se está no período gratuito e não está assinado
        const diasRestantes = modulosComAcessoGratuito[modulo.nome.toLowerCase()]?.diasRestantes // Obtém os dias restantes do período gratuito
        return (
          <div key={modulo.nome} className="module-option">
            <input
              type="checkbox"
              id={modulo.nome}
              name={modulo.nome}
              value={modulo.nome}
              checked={selectedModules.includes(modulo.nome) || isSubscribed} // Marca o checkbox se estiver selecionado ou assinado
              onChange={handleModuleChange}
              disabled={isSubscribed} // Desabilita o checkbox se estiver assinado
            />
            <label
              htmlFor={modulo.nome}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 style={{ margin: '0', textAlign: 'left', fontWeight: 'bold' }}>{`${capitalize(
                  modulo.nome
                )}`}</h4>
                <Link
                  to={`/module-description/${modulo.nome}`}
                  className="saiba-mais-link"
                  style={{
                    marginLeft: '10px',
                    textDecoration: 'underline',
                    color: '#6c757d',
                    cursor: 'pointer'
                  }}
                >
                  Saiba mais
                </Link>
              </div>
              <p style={{ margin: '0' }}>{`Preço: R$${modulo.preco.toFixed(2)}/mês`}</p>
              {isSubscribed ? (
                <span style={{ color: 'green', marginTop: '-2px' }}>Módulo Assinado</span> // Exibe "Módulo Assinado" se estiver assinando
              ) : (
                isFreePeriod && (
                  <span
                    style={{ color: '#b8860b', marginTop: '-2px' }}
                    title={
                      isFreePeriod
                        ? `Faltam ${diasRestantes} dias para o período gratuito expirar`
                        : ''
                    }
                  >
                    Em período Gratuito
                  </span> // Exibe "Em período Gratuito" se estiver no período gratuito
                )
              )}
            </label>
          </div>
        )
      })}
    </div>
  )
}
