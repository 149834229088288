import { useState, useEffect, useContext, useRef } from 'react'
import { loadFaceApiModels } from '../../../../../../src/components/WebcamRecognition/faceApiHelper.js'
import { fetchData } from '../../../../../../src/backend/model.js'
import { config } from '../../../../../../src/backend/configConexaoGeral.js'
import { AuthContext } from '../../../../../../src/context/AuthContext.jsx'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import WebcamRecognition from '../../../../../../src/components/WebcamRecognition/WebcamRecognition.jsx'
import { Container, Form } from 'react-bootstrap'

const CadastroFacial = ({
  isForGerenteOperacoes = false,
  modoAutenticacao = false,
  funcionarioNome = '',
  onFaceCadastrada,
  ...props
}) => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [todosFuncionarios, setTodosFuncionarios] = useState([])
  const [selectedFuncionarioId, setSelectedFuncionarioId] = useState(props.funcionarioId || '')
  const [autoCapture, setAutoCapture] = useState(false) // Ativa autoCapture no modoAutenticacao
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')
  const webcamRef = useRef(null)

  useEffect(() => {
    const loadModels = async () => {
      try {
        await loadFaceApiModels()
      } catch (error) {
        console.error('Erro ao carregar os modelos do face-api:', error)
      }
    }
    loadModels()
  }, [])

  // Busca todos os funcionários apenas se não for modo de autenticação
  useEffect(() => {
    if (!modoAutenticacao) {
      const fetchTodosFuncionarios = async () => {
        try {
          const url = `${config.host}/funcionariosAtivos`
          const response = await fetchData(url, {
            tenantId,
            rota_base: RotaBase,
            requisicao: 'listarFuncionarios'
          })
          setTodosFuncionarios(response || [])
        } catch (error) {
          console.error('Erro ao listar funcionários:', error)
        }
      }
      fetchTodosFuncionarios()
    }
  }, [RotaBase, tenantId, modoAutenticacao])

  useEffect(() => {
    if (isForGerenteOperacoes && props.funcionarioId) {
      setSelectedFuncionarioId(props.funcionarioId)
    }
  }, [isForGerenteOperacoes, props.funcionarioId])

  const handleSaveFace = async descriptor => {
    try {
      const url = `${config.host}/funcionariosAtivos`
      const dados = {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'gravarLeituraFacial',
        funcionarioId: selectedFuncionarioId || props.funcionarioId,
        descriptor
      }

      const response = await fetchData(url, dados)
      if (response.message) {
        props.setFaceCadastrada(true)
        setAlertVariant('success')
        setAlertMessage('Leitura facial salva com sucesso!')
        setShowAlert(true)
        if (onFaceCadastrada) {
          onFaceCadastrada()
        }
      }
    } catch (error) {
      console.error('Erro ao salvar leitura facial:', error)
      setAlertVariant('danger')
      setAlertMessage(error.message)
      setShowAlert(true)
    }
  }

  const handleIdentifyFace = async descriptor => {
    try {
      const url = `${config.host}/funcionariosAtivos`
      const response = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'lerLeituraFacial',
        descriptor
      })
      if (response.colaborador) {
        setAlertVariant('success')
        setAlertMessage(`Olá ${response.colaborador.Nome}!`)
        setShowAlert(true)

        // Verifica permissão do gerente após identificação bem-sucedida
        if (onFaceCadastrada) {
          onFaceCadastrada(response.colaborador) // Passa o colaborador identificado
        }
      } else {
        setAlertVariant('warning')
        setAlertMessage('Nenhuma correspondência encontrada.')
        setShowAlert(true)
      }
    } catch (error) {
      console.error('Erro ao identificar leitura facial:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao identificar leitura facial')
      setShowAlert(true)
    }
  }

  const handleCaptureForSave = descriptor => {
    if (selectedFuncionarioId) {
      handleSaveFace(descriptor)
    }
  }

  const handleCaptureForIdentify = descriptor => {
    handleIdentifyFace(descriptor)
  }

  const handleAutoCapture = descriptor => {
    handleIdentifyFace(descriptor)
  }

  return (
    <Container>
      <h2>
        {modoAutenticacao
          ? 'Autenticação Facial'
          : isForGerenteOperacoes
          ? `Cadastrar face para ${funcionarioNome}`
          : 'Cadastro Facial'}
      </h2>

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
          timeOut={3000}
        />
      )}

      {/* Renderiza apenas a identificação facial no modoAutenticacao */}
      {modoAutenticacao ? (
        <WebcamRecognition
          onCapture={() => {}}
          onIdentify={handleCaptureForIdentify}
          autoCapture={autoCapture}
          autoCaptureCallback={handleAutoCapture}
          ref={webcamRef}
          selectedFuncionarioId={selectedFuncionarioId}
          isForGerenteOperacoes={isForGerenteOperacoes}
          cadastrarFuncionario={!modoAutenticacao}
        />
      ) : (
        <>
          {!isForGerenteOperacoes && (
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="select-colaborador">Selecione o Colaborador</label>
              <Form.Control
                id="select-colaborador"
                as="select"
                value={selectedFuncionarioId}
                onChange={e => setSelectedFuncionarioId(e.target.value)}
              >
                <option value="">Selecione um colaborador</option>
                {todosFuncionarios.map(func => (
                  <option key={func._id} value={func._id}>
                    {func.Nome}
                  </option>
                ))}
              </Form.Control>
            </div>
          )}

          <WebcamRecognition
            onCapture={handleCaptureForSave}
            onIdentify={handleCaptureForIdentify}
            autoCapture={autoCapture}
            autoCaptureCallback={handleAutoCapture}
            ref={webcamRef}
            selectedFuncionarioId={selectedFuncionarioId}
            isForGerenteOperacoes={isForGerenteOperacoes}
          />

          {!isForGerenteOperacoes && (
            <div style={{ marginTop: '10px' }}>
              <Form.Check
                type="checkbox"
                label="Leitura facial automática"
                checked={autoCapture}
                onChange={() => setAutoCapture(!autoCapture)}
              />
            </div>
          )}
        </>
      )}
    </Container>
  )
}

export default CadastroFacial
