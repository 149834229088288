//WebcamRecognition.jsx
import { useRef, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as faceapi from 'face-api.js'
import Webcam from 'react-webcam'
import { loadFaceApiModels } from './faceApiHelper'
import { ShowAlertGeneric } from '../../../src/utils/form-utils.jsx'
const WebcamRecognition = ({
  onCapture,
  onIdentify,
  autoCapture,
  selectedFuncionarioId,
  cadastrarFuncionario = true,
  isForGerenteOperacoes = false
}) => {
  const webcamRef = useRef(null)
  const [modelsLoaded, setModelsLoaded] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')

  useEffect(() => {
    const loadModels = async () => {
      await loadFaceApiModels()
      setModelsLoaded(true)
    }
    loadModels()
  }, [])

  // Auto-captura para identificação
  const handleCaptureForIdentify = useCallback(async () => {
    if (!modelsLoaded) {
      console.error('Modelos ainda não foram carregados.')
      return
    }

    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      await processImage(imageSrc, (error, descriptor) => {
        if (error) {
          setAlertMessage(error)
          setAlertVariant('danger')
          setShowAlert(true) // Exibe mensagem local
        } else if (descriptor) {
          onIdentify(descriptor) // Chama a função de identificação no pai
        }
      })
    }
  }, [modelsLoaded, onIdentify, webcamRef])

  useEffect(() => {
    if (autoCapture && modelsLoaded) {
      const intervalId = setInterval(() => handleCaptureForIdentify(), 3000)
      return () => clearInterval(intervalId)
    }
  }, [autoCapture, modelsLoaded, handleCaptureForIdentify])

  // Captura para cadastrar a face

  const handleCaptureForSave = async () => {
    if (!modelsLoaded) {
      console.error('Modelos ainda não foram carregados.')
      return
    }
    console.log('selectedFuncionarioId no WebcamRecognition:', selectedFuncionarioId)
    if (!selectedFuncionarioId) {
      setAlertMessage('Selecione um funcionário para cadastrar a face.')
      setAlertVariant('warning')
      setShowAlert(true)
      return
    }
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot()

      await processImage(imageSrc, (error, descriptor) => {
        if (error) {
          setAlertMessage(error)
          setAlertVariant('danger')
          setShowAlert(true)
        } else if (descriptor) {
          // Converter o descritor para um array simples aqui
          const plainDescriptor = Array.from(descriptor)

          onCapture(plainDescriptor)
        } else {
          console.warn('Nenhum descritor recebido.')
        }
      })
    }
  }

  // Captura para identificar a face

  // Função auxiliar para processar imagem e retornar descritor facial
  const processImage = async (imageSrc, callback) => {
    const img = new Image()
    img.src = imageSrc
    img.onload = async () => {
      const detections = await faceapi
        .detectSingleFace(img)
        .withFaceLandmarks()
        .withFaceDescriptor()

      if (detections && callback) {
        callback(null, detections.descriptor) // Retorna o descritor sem erro
      } else {
        callback('Nenhuma face detectada.', null) // Retorna erro
      }
    }
    img.onerror = err => {
      console.error('Erro ao carregar imagem:', err)
      callback('Erro ao carregar imagem.', null)
    }
  }

  return (
    <div>
      <ShowAlertGeneric
        showAlert={showAlert}
        message={alertMessage}
        variant={alertVariant}
        setShowAlert={setShowAlert}
        timeOut={4000}
      />
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ width: '400px', height: '300px', borderRadius: '20px' }}
      />
      <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
        {cadastrarFuncionario && (
          <button onClick={handleCaptureForSave} disabled={!modelsLoaded}>
            {modelsLoaded ? 'Cadastrar Face' : 'Carregando Modelos...'}
          </button>
        )}
        {!isForGerenteOperacoes && (
          <button onClick={handleCaptureForIdentify} disabled={!modelsLoaded}>
            {modelsLoaded ? 'Identificar Face' : 'Carregando Modelos...'}
          </button>
        )}
      </div>
    </div>
  )
}
WebcamRecognition.propTypes = {
  onCapture: PropTypes.func.isRequired,
  onIdentify: PropTypes.func.isRequired,
  autoCapture: PropTypes.bool.isRequired,
  selectedFuncionarioId: PropTypes.string,
  cadastrarFuncionario: PropTypes.bool,
  isForGerenteOperacoes: PropTypes.bool
}

export default WebcamRecognition
