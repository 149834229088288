import { useState, useContext, useEffect } from 'react'
import {
  Container,
  Form,
  Table,
  Button,
  Modal,
  Spinner,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../backend/configConexaoGeral.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
//import Select from 'react-select'

import Banner from '../../CadastroGeral/Banner.jsx'

const GerenciamentoSetores = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [setores, setSetores] = useState([])
  const [funcionarios, setFuncionarios] = useState([])
  const [novoSetor, setNovoSetor] = useState('')
  const [selectedSetor, setSelectedSetor] = useState('')
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'
  const [selectedFuncionarios, setSelectedFuncionarios] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [mode, setMode] = useState('adicionar') // 'adicionar' ou 'remover'
  const [allFuncionarios, setAllFuncionarios] = useState([])
  const [showConfirmExcluirSetor, setShowConfirmExcluirSetor] = useState(false)
  const [showConfirmRemoverFuncionario, setShowConfirmRemoverFuncionario] = useState(false)

  // Estado para controlar o setor a ser excluído
  const [setorParaExcluir, setSetorParaExcluir] = useState(null)
  // Estado consolidado para adicionar/remover funcionários
  const [selectedFuncionariosAddRemove, setSelectedFuncionariosAddRemove] = useState([])

  const fetchDados = async () => {
    setLoading(true)
    try {
      // Buscar setores
      const setoresUrl = `${config.host}/gerenciarSetores`
      const setoresResponse = await fetchData(setoresUrl, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'listarTodosOsSetores'
      })
      const setoresData = setoresResponse.setores || []
      setSetores(setoresData)

      // Buscar todos os funcionários
      const funcionariosUrl = `${config.host}/gerenciarSetores`
      const funcionariosResponse = await fetchData(funcionariosUrl, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'listarFuncionarios'
      })
      const todosFuncionarios = funcionariosResponse || []
      setAllFuncionarios(todosFuncionarios)

      // Extrair funcionários com a informação do setor
      const funcionariosComSetor = setoresData.flatMap(setor =>
        (setor.funcionarios || []).map(funcionario => ({
          ...funcionario,
          setor: setor.setor,
          setorId: setor._id
        }))
      )

      setFuncionarios(funcionariosComSetor)
    } catch (error) {
      console.error('Erro ao carregar dados:', error)
      setAlertMessage('Erro ao carregar dados')
      setAlertVariant('danger')
      setShowAlert(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDados()
  }, [RotaBase, tenantId])

  // Preparar opções para o Select
  const optionsAddRemove = allFuncionarios.map(funcionario => ({
    value: funcionario._id,
    label: funcionario.Nome
  }))

  const handleCriarSetor = async () => {
    if (!novoSetor.trim()) {
      setAlertVariant('danger')
      setAlertMessage('O nome do setor não pode estar vazio.')
      setShowAlert(true)
      return
    }

    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`
      const criarSetorResponse = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        setor: novoSetor,
        requisicao: 'criarSetor'
      })

      console.log('criarSetorResponse', criarSetorResponse)
      // Supondo que a API retorne o novo setor criado com _id
      setSetores(prevSetores => [...prevSetores, criarSetorResponse.setor])

      setNovoSetor('')
      setAlertVariant('success')
      setAlertMessage('Setor criado com sucesso!')
      setShowAlert(true)
    } catch (error) {
      console.error('Erro ao criar setor:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao criar setor')
      setShowAlert(true)
    } finally {
      setLoading(false)
    }
  }

  const handleAdicionarRemoverFuncionarios = async () => {
    if (mode === 'adicionar' && !selectedSetor) {
      setAlertVariant('danger')
      setAlertMessage('Por favor, selecione um setor.')
      setShowAlert(true)
      return
    }

    if (selectedFuncionariosAddRemove.length === 0) {
      setAlertVariant('danger')
      setAlertMessage(`Por favor, selecione pelo menos um funcionário para ${mode}.`)
      setShowAlert(true)
      return
    }

    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`

      if (mode === 'adicionar') {
        for (const funcionario of selectedFuncionariosAddRemove) {
          await fetchData(url, {
            tenantId,
            rota_base: RotaBase,
            setor: selectedSetor,
            funcionarioId: funcionario.value,
            requisicao: 'adicionarFuncionarioAoSetor'
          })
        }
        setAlertMessage('Funcionários adicionados ao setor com sucesso!')
      } else if (mode === 'remover') {
        for (const funcionario of selectedFuncionariosAddRemove) {
          await fetchData(url, {
            tenantId,
            rota_base: RotaBase,
            setor: funcionario.setor,
            funcionarioId: funcionario.value,
            requisicao: 'removerFuncionarioDoSetor'
          })
        }
        setAlertMessage('Funcionários removidos dos setores com sucesso!')
      }

      await fetchDados()

      setAlertVariant('success')
      setShowAlert(true)
      setSelectedFuncionariosAddRemove([])
      if (mode === 'remover') {
        setShowConfirmModal(false)
      }
    } catch (error) {
      console.error(`Erro ao ${mode} funcionários ao setor:`, error)
      setAlertVariant('danger')
      setAlertMessage(`Erro ao ${mode} funcionários ao setor`)
      setShowAlert(true)
    } finally {
      setLoading(false)
    }
  }
  const handleVerificarSetorAntesDeExcluir = async setor => {
    try {
      const url = `${config.host}/gerenciarSetores`
      const dados = {
        requisicao: 'verificarSetorAntesDeExcluir',
        setor,
        tenantId,
        rota_base: RotaBase
      }

      const response = await fetchData(url, dados)

      // Verifica se há cargos associados ao setor
      if (response?.cargos?.length > 0) {
        const cargos = response.cargos.map(cargo => cargo.nome).join(', ')
        setAlertMessage(
          `O setor "${setor}" possui os seguintes cargos associados: ${cargos}. Deseja realmente excluir este setor?`
        )
        setAlertVariant('warning')
      } else {
        setAlertMessage(
          `O setor "${setor}" não possui cargos associados. Você pode excluí-lo com segurança.`
        )
        setAlertVariant('success')
      }

      setSetorParaExcluir(setor) // Armazena o setor a ser excluído
      setShowConfirmExcluirSetor(true) // Mostra o modal de exclusão do setor
    } catch (error) {
      console.error('Erro ao verificar setor antes de excluir:', error)
      setAlertMessage('Erro ao verificar setor antes de excluir.')
      setAlertVariant('danger')
      setShowAlert(true)
    }
  }

  const handleExcluirSetor = async setor => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        setor,
        requisicao: 'excluirSetor'
      })
      setSetores(prevSetores => prevSetores.filter(s => s.setor !== setor))
      setAlertVariant('success')
      setAlertMessage('Setor excluído com sucesso!')
      setShowAlert(true)
    } catch (error) {
      console.error('Erro ao excluir setor:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao excluir setor')
      setShowAlert(true)
    } finally {
      setLoading(false)
    }
  }

  // Filtrar funcionários com base no modo e no termo de pesquisa
  const filteredFuncionarios = funcionarios.filter(funcionario =>
    funcionario.Nome.toLowerCase().includes(searchTerm)
  )

  // Preparar dados para o Select com base no modo
  const selectOptions =
    mode === 'adicionar'
      ? allFuncionarios.filter(f => !funcionarios.find(func => func._id === f._id)) // Funcionários sem setor
      : funcionarios // Todos os funcionários com setor

  return (
    <Container fluid className="p-4">
      {/* Título */}
      <div className="d-flex justify-content-center mb-4">
        <Banner title="Gerenciamento de Setores" width="100%" fontSize={47} />
      </div>

      {/* Campo para criar novo setor */}
      <Form.Group className="mb-1">
        <Form.Label>Nome do Novo Setor</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite o nome do novo setor"
          value={novoSetor}
          onChange={e => setNovoSetor(e.target.value)}
          style={{ width: '30%' }}
        />
        <Button
          variant="primary"
          className="mt-2"
          onClick={handleCriarSetor}
          style={{ width: '30%' }}
        >
          Criar Setor
        </Button>
      </Form.Group>
      {/* Seção para listar setores existentes */}
      <div className="setores-container" style={{ width: '30%' }}>
        <h4>Setores Existentes</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nome do Setor</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {setores.map(setor => (
              <tr key={setor._id}>
                <td>{setor.setor}</td>
                <td style={{ width: '10%' }}>
                  <Button
                    variant="danger"
                    onClick={() => handleVerificarSetorAntesDeExcluir(setor.setor)}
                  >
                    Excluir
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* Modal de confirmação para exclusão de setor */}
      <Modal
        show={showConfirmExcluirSetor}
        onHide={() => setShowConfirmExcluirSetor(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertVariant === 'warning' && setorParaExcluir ? (
            <>
              <p>
                O setor <strong>"{setorParaExcluir}"</strong> possui os seguintes cargos associados:
              </p>
              <ul>
                {alertMessage
                  .match(/: (.+)\./)?.[1]
                  .split(', ')
                  .map((cargo, index) => (
                    <li key={index}>{cargo}</li>
                  ))}
              </ul>
            </>
          ) : (
            <p>
              O setor <strong>"{setorParaExcluir}"</strong> não possui cargos associados.
            </p>
          )}
          <p>Tem certeza que deseja excluir este setor?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmExcluirSetor(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleExcluirSetor(setorParaExcluir)
              setShowConfirmExcluirSetor(false)
            }}
          >
            Confirmar Exclusão
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toggle para selecionar modo */}
      <Form.Group className="mb-3">
        <Form.Label>Modo de Operação</Form.Label>
        <ToggleButtonGroup
          type="radio"
          name="mode"
          value={mode}
          onChange={val => {
            setMode(val)
            setSelectedFuncionariosAddRemove([])
            setSelectedSetor('')
            setSearchTerm('')
          }}
        >
          <ToggleButton id="tbg-radio-1" value="adicionar" variant="outline-primary">
            Adicionar Funcionário
          </ToggleButton>
          <ToggleButton id="tbg-radio-2" value="remover" variant="outline-danger">
            Remover Funcionário
          </ToggleButton>
        </ToggleButtonGroup>
      </Form.Group>

      {/* Seção para Adicionar/Remover Funcionários */}
      <div className="mb-5">
        <h4>
          {mode === 'adicionar' ? 'Adicionar Funcionário ao Setor' : 'Remover Funcionário do Setor'}
        </h4>

        {/* Barra de pesquisa */}
        <Form.Group className="mb-3">
          <Form.Label>Pesquisar Funcionário</Form.Label>
          <Form.Control
            type="text"
            placeholder="Pesquisar funcionário"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value.toLowerCase())}
            style={{ width: '30%' }}
          />
        </Form.Group>

        {/* Seleção de Setor (apenas para adicionar) */}
        {mode === 'adicionar' && (
          <Form.Group className="mb-3">
            <Form.Label>Selecionar Setor</Form.Label>
            <Form.Control
              as="select"
              value={selectedSetor}
              onChange={e => setSelectedSetor(e.target.value)}
            >
              <option value="">Selecione um setor</option>

              {setores?.map(setor => (
                <option key={setor?._id} value={setor?.setor}>
                  {setor?.setor}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {/* Tabela para adicionar/remover funcionários */}
        <Table striped bordered hover style={{ width: '30%' }}>
          <thead>
            <tr>
              <th>
                <Form.Check
                  type="checkbox"
                  onChange={() => {
                    if (selectedFuncionariosAddRemove.length === selectOptions.length) {
                      setSelectedFuncionariosAddRemove([])
                    } else {
                      setSelectedFuncionariosAddRemove(
                        selectOptions.map(func => ({
                          value: func._id,
                          label: func.Nome,
                          setor: func.setor
                        }))
                      )
                    }
                  }}
                  checked={
                    selectedFuncionariosAddRemove.length === selectOptions.length &&
                    selectOptions.length > 0
                  }
                />
              </th>
              <th>Funcionário</th>
              <th>Setor Atual</th>
            </tr>
          </thead>
          <tbody>
            {selectOptions
              .filter(funcionario => {
                if (mode === 'adicionar') {
                  return (
                    funcionario.Nome.toLowerCase().includes(searchTerm) &&
                    !funcionarios.find(f => f._id === funcionario._id)
                  )
                } else {
                  return funcionario.Nome.toLowerCase().includes(searchTerm)
                }
              })
              .map(funcionario => (
                <tr key={funcionario._id}>
                  <td style={{ width: '7%' }}>
                    <Form.Check
                      type="checkbox"
                      checked={selectedFuncionariosAddRemove.some(f => f.value === funcionario._id)}
                      onChange={() => {
                        const exists = selectedFuncionariosAddRemove.find(
                          f => f.value === funcionario._id
                        )
                        if (exists) {
                          setSelectedFuncionariosAddRemove(
                            selectedFuncionariosAddRemove.filter(f => f.value !== funcionario._id)
                          )
                        } else {
                          setSelectedFuncionariosAddRemove([
                            ...selectedFuncionariosAddRemove,
                            {
                              value: funcionario._id,
                              label: funcionario.Nome,
                              setor: funcionario.setor
                            }
                          ])
                        }
                      }}
                    />
                  </td>
                  <td>{funcionario.Nome}</td>
                  <td>{funcionario.setor || 'Sem Setor'}</td>
                </tr>
              ))}
          </tbody>
        </Table>

        {/* Botão para adicionar/remover funcionários */}
        <Button
          variant={mode === 'adicionar' ? 'primary' : 'danger'}
          onClick={
            mode === 'remover'
              ? () => setShowConfirmModal(true)
              : handleAdicionarRemoverFuncionarios
          }
          style={{ width: '15%' }}
        >
          {mode === 'adicionar'
            ? 'Adicionar Funcionários ao Setor'
            : 'Remover Funcionários do Setor'}
        </Button>
      </div>

      {/* Modal de confirmação para remoção */}
      <Modal
        show={showConfirmRemoverFuncionario}
        onHide={() => setShowConfirmRemoverFuncionario(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Remoção</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza que deseja remover os seguintes funcionários?
          <ul>
            {selectedFuncionariosAddRemove.map(func => (
              <li key={func.value}>
                {func.label} - {func.setor}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmRemoverFuncionario(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleAdicionarRemoverFuncionarios}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Indicador de carregamento */}
      {loading && <Spinner animation="border" className="mt-3" />}

      {/* Alerta */}
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default GerenciamentoSetores
