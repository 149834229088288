// ModelagemMenu.js

import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PrivateMenuItem from '../../../src/components/PrivateMenuItem.jsx'

const ModelagemMenu = () => {
  return (
    <PrivateMenuItem tela="Modelagem">
      <NavDropdown title="Modelagem" id="modelagem-dropdown">
        <PrivateMenuItem tela="Cadastro Cliente Modelagem">
          <NavDropdown.Item
            as={Link}
            to="/modelagem-cadastro-cliente"
            className="nav-dropdown-item"
          >
            Cadastro Cliente
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Modelagem Pesquisa e Edição Cliente">
          <NavDropdown.Item
            as={Link}
            to="/modelagem-pesquisa-e-edicao-cliente"
            className="nav-dropdown-item"
          >
            Pesquisa e Edição de Cliente
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Cadastro Serviço Modelagem">
          <NavDropdown.Item
            as={Link}
            to="/modelagem-cadastro-servico-modelagem"
            className="nav-dropdown-item"
          >
            Cadastro de Pedido
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Cadastro Grau Dificuldade Modelagem">
          <NavDropdown.Item
            as={Link}
            to="/modelagem-cadastro-grau-dificuldade-modelagem"
            className="nav-dropdown-item"
          >
            Cadastro Grau Dificuldade Modelagem
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Cadastro Preços Modelagem">
          <NavDropdown.Item
            as={Link}
            to="/modelagem-cadastro-precos-modelagem"
            className="nav-dropdown-item"
          >
            Cadastro Preços Modelagem
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Editar Preços Modelagem">
          <NavDropdown.Item
            as={Link}
            to="/modelagem-editar-precos-modelagem"
            className="nav-dropdown-item"
          >
            Editar Preços Modelagem
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Editar Modelagem">
          <NavDropdown.Item as={Link} to="/editando-modelagems" className="nav-dropdown-item">
            Editar Pedido
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Finalização de Modelagem">
          <NavDropdown.Item as={Link} to="/finalizacao-de-modelagems" className="nav-dropdown-item">
            Finalização de Modelagens
          </NavDropdown.Item>
        </PrivateMenuItem>
      </NavDropdown>
    </PrivateMenuItem>
  )
}

export default ModelagemMenu
