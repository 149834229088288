// PlotagemMenu.js

import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PrivateMenuItem from '../../../src/components/PrivateMenuItem.jsx'

const PlotagemMenu = () => {
  return (
    <PrivateMenuItem tela="Plotagem">
      <NavDropdown title="Plotagem" id="plotagem-dropdown">
        <PrivateMenuItem tela="Cadastro Cliente Plotagem">
          <NavDropdown.Item as={Link} to="/plotagem-cadastro-cliente" className="nav-dropdown-item">
            Cadastro Cliente
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Plotagem Pesquisa e Edição Cliente">
          <NavDropdown.Item
            as={Link}
            to="/plotagem-pesquisa-e-edicao-cliente"
            className="nav-dropdown-item"
          >
            Pesquisa e Edição de Cliente
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Cadastro de Plotagem">
          <NavDropdown.Item
            as={Link}
            to="/plotagem-cadastro-plotagem"
            className="nav-dropdown-item"
          >
            Cadastro Plotagem
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Editar Plotagem">
          <NavDropdown.Item as={Link} to="/editando-plotagems" className="nav-dropdown-item">
            Editar Plotagem
          </NavDropdown.Item>
        </PrivateMenuItem>
        <PrivateMenuItem tela="Configurar Valor Plotagem">
          <NavDropdown.Item as={Link} to="/configurar-valor-plotagem" className="nav-dropdown-item">
            Configurar Valor Plotagem
          </NavDropdown.Item>
        </PrivateMenuItem>
      </NavDropdown>
    </PrivateMenuItem>
  )
}

export default PlotagemMenu
