// funcionarios-ativos.jsx

import * as faceapi from 'face-api.js'
import { loadFaceApiModels } from '../../../WebcamRecognition/faceApiHelper.js'
//import { converterHorasParaHorasEMinutos } from './HorarioFuncionarioModal.jsx'
import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../backend/configConexaoGeral.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { Container, Form, Row, Col, Table, Button } from 'react-bootstrap'
//import HorarioFuncionarioModal from './HorarioFuncionarioModal.jsx'
import Banner from '../../CadastroGeral/Banner.jsx'
import 'react-datepicker/dist/react-datepicker.css'
import WebcamRecognition from '../../../WebcamRecognition/WebcamRecognition.jsx'
import AlterarSituacaoModal from './situacaoModal.jsx'
import { copyStringIntoBuffer } from 'pdf-lib'
import { useNavigate } from 'react-router-dom'
const ConfigurarFuncionariosAtivos = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [setores, setSetores] = useState([])
  const [setorSelecionado, setSetorSelecionado] = useState('')
  //const [funcionarios, setFuncionarios] = useState([])
  // const [funcionariosAtivos, setFuncionariosAtivos] = useState(new Set())
  //const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedFuncionario, setSelectedFuncionario] = useState(null)
  // const [selectedDate, setSelectedDate] = useState(new Date())
  const location = useLocation()
  const origem = location.state?.origem
  // Estados para armazenar todos os funcionários e o selecionado
  const [todosFuncionarios, setTodosFuncionarios] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [selectedFuncionarioIdForFace, setSelectedFuncionarioIdForFace] = useState('')
  const [autoCapture, setAutoCapture] = useState(false)
  const [modelsLoaded, setModelsLoaded] = useState(false)
  const [funcionarioIdentificado, setFuncionarioIdentificado] = useState(null)
  const [habilitarBotaoPresenca, setHabilitarBotaoPresenca] = useState(false)
  const [funcionariosPresentes, setFuncionariosPresentes] = useState([])
  const [totalFuncionariosPresentes, setTotalFuncionariosPresentes] = useState([])
  const [funcionariosAusentes, setFuncionariosAusentes] = useState([])
  const [mostrarAusentes, setMostrarAusentes] = useState(false)
  const [mostrarSubmenu, setMostrarSubmenu] = useState(false)

  const [todasAsSituacoes, setTodasAsSituacoes] = useState([])

  const webcamRef = useRef(null)
  const navigate = useNavigate()
  // const ajustarFusoHorario = (data, offset) => {
  //   const date = new Date(data)
  //   const utcDate = date.getTime() + date.getTimezoneOffset() * 60000
  //   const adjustedDate = new Date(utcDate + 3600000 * offset)
  //   return adjustedDate
  // }

  const handleDeleteSituacao = funcionario => {
    setFuncionariosPresentes(
      prevPresentes => prevPresentes.filter(f => f.situacaoId !== funcionario.situacaoId) // Exclui pelo ID da situação
    )
    setSelectedFuncionario(null) // Limpa o estado
  }

  const handleMouseEnter = () => setMostrarAusentes(true)
  const handleMouseLeave = () => setMostrarAusentes(false)

  function obterStatusEmpresa(valor) {
    switch (valor) {
      case 1:
        return 'entrou na empresa'
      case 2:
        return 'saiu para o café da manhã'
      case 3:
        return 'voltou do café da manhã'
      case 4:
        return 'saiu para o almoço'
      case 5:
        return 'voltou do almoço'
      case 6:
        return 'saiu para o café da tarde'
      case 7:
        return 'voltou do café da tarde'
      case 8:
        return 'foi embora'
      default:
        return 'Valor inválido'
    }
  }
  const handleIdentifyFace = useCallback(
    async descriptor => {
      try {
        const url = `${config.host}/funcionariosAtivos`
        const response = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'lerLeituraFacial',
          descriptor
        })

        if (response.colaborador) {
          setFuncionarioIdentificado(response.colaborador)
          setHabilitarBotaoPresenca(true)
          setAlertVariant('success')
          setAlertMessage(`Ola ${response.colaborador.Nome} !`)
          setShowAlert(true)
        } else {
          setFuncionarioIdentificado(null)
          setHabilitarBotaoPresenca(false)
          setAlertVariant('warning')
          setAlertMessage('Nenhuma correspondência encontrada.')
          setShowAlert(true)
        }
      } catch (error) {
        console.error('Erro ao identificar leitura facial:', error)
        setFuncionarioIdentificado(null)
        setHabilitarBotaoPresenca(false)
        setAlertVariant('danger')
        setAlertMessage('Erro ao identificar leitura facial')
        setShowAlert(true)
      }
    },
    [RotaBase, tenantId]
  )

  const handleMarcarPresenca = useCallback(
    async funcionario => {
      try {
        setShowAlert(false)
        const numeroRegistroUrl = `${config.host}/funcionariosAtivos`
        const numeroRegistroResponse = await fetchData(numeroRegistroUrl, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'numeroDoRegistroDePonto',
          funcionarioId: funcionario.funcionarioId
        })

        const situacao = obterStatusEmpresa(numeroRegistroResponse.proximoNumero)

        const dados = {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'registrarPonto',
          funcionarioId: funcionario.funcionarioId,
          ativo: true,
          setor: setorSelecionado, // Aqui você está usando 'setorSelecionado'
          situacao
        }

        const url = `${config.host}/funcionariosAtivos`
        const responseFuncionariosAtivos = await fetchData(url, dados)

        const novoRegistro = responseFuncionariosAtivos.registro

        if (novoRegistro) {
          console.log('novoRegistro:', novoRegistro)
          console.log('responseFuncionariosAtivos:', responseFuncionariosAtivos)
          const data = new Date(responseFuncionariosAtivos.registro.data)
          const adjustedDate = new Date(data.getTime() + 3 * 60 * 60 * 1000) // Ajuste para UTC-3
          const horaDoPonto = adjustedDate.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })
          // Atualiza os funcionários presentes
          console.log('horaDoPonto:', horaDoPonto)
          setFuncionariosPresentes(prevPresentes => [
            ...prevPresentes,
            {
              ...funcionario,
              //_id: novoRegistro._id,
              situacaoId: novoRegistro._id,
              horaDoPonto: horaDoPonto,
              situacao
            }
          ])
          console.log('Novo registro recebido no handleMarcarPresenca:', novoRegistro)
          console.log('selectedFuncionario:', selectedFuncionario)
          setSelectedFuncionario(prev => ({
            ...prev,
            data: novoRegistro.data,
            situacao: novoRegistro.situacao,
            situacaoId: novoRegistro._id
          }))
        }

        // setFuncionariosPresentes(prevPresentes => {
        //   const novosPresentes = [
        //     ...prevPresentes,
        //     {
        //       ...funcionario,
        //       _id: funcionario.funcionarioId,
        //       horaDoPonto,
        //       situacao
        //     }
        //   ]
        //   return novosPresentes
        // })

        setAlertVariant('success')
        setAlertMessage(`Registro feito.`)
        setShowAlert(true)
        setAutoCapture(prevState => prevState)
        // Remover foco de qualquer componente ativo
        if (document.activeElement) {
          document.activeElement.blur()
        }
      } catch (error) {
        console.error('Erro ao marcar presença:', error)

        // Exibe a mensagem de erro do backend
        setAlertVariant('danger')
        setAlertMessage(error.message || 'Erro ao marcar presença. Tente novamente mais tarde.')
        setShowAlert(true)
      }
    },
    [RotaBase, tenantId, setorSelecionado]
  )
  // crie um useefect para logar o valor de selectedFuncionario
  useEffect(() => {
    console.log('selectedFuncionario no log:', selectedFuncionario)
  }, [selectedFuncionario])
  // Busca todos os funcionários
  useEffect(() => {
    const gerarTodasAsSituacoes = () => {
      const situacoes = []
      for (let i = 1; i <= 8; i++) {
        situacoes.push(obterStatusEmpresa(i))
      }
      return situacoes
    }

    const situacoesGeradas = gerarTodasAsSituacoes()
    setTodasAsSituacoes(situacoesGeradas)
  }, [])

  // useEffect para chamar a rota listarFuncionarios
  useEffect(() => {
    const fetchTodosFuncionarios = async () => {
      try {
        const url = `${config.host}/funcionariosAtivos`
        const response = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionarios' // Requisição para listar todos os funcionários
        })

        // Garanta que response seja um array de funcionários
        setTodosFuncionarios(response || [])
      } catch (error) {
        console.error('Erro ao listar todos os funcionários:', error)
      }
    }

    fetchTodosFuncionarios()
  }, [RotaBase, tenantId])

  useEffect(() => {
    const fetchSetores = async () => {
      try {
        const url = `${config.host}/gerenciarSetores`

        const setoresResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarTodosOsSetores'
        })

        setSetores(setoresResponse.setores || [])
      } catch (error) {
        console.error('Erro ao buscar setores:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar setores')
        setShowAlert(true)
      }
    }

    fetchSetores()
  }, [RotaBase, tenantId])

  useEffect(() => {
    if (origem === 'Corte' && setores.length > 0) {
      // Encontra o setor com o nome "Corte"
      const setorCorte = setores.find(setor => setor.setor.toLowerCase() === 'corte')
      if (setorCorte) {
        setSetorSelecionado(setorCorte._id)
      }
    }
  }, [origem, setores])
  useEffect(() => {
    const fetchFuncionarios = async () => {
      //  setLoading(true)
      try {
        const url = `${config.host}/funcionariosAtivos`

        const funcionariosResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionariosAtivosTodosSetores'
          // data: selectedDate // Envia a data selecionada
        })

        // Verifica se funcionariosResponse é null e retorna sem fazer nada
        if (!funcionariosResponse) {
          // setLoading(false)
          return
        }
        console.log('funcionariosResponse3:', funcionariosResponse)
        // Mapeia os funcionários e formata os dados diretamente
        const funcionariosComHorarios = funcionariosResponse.map(funcionario => {
          const data = new Date(funcionario.data)
          const adjustedDate = new Date(data.getTime() + 3 * 60 * 60 * 1000) // Ajuste para UTC-3
          const horaDoPonto = adjustedDate.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })

          return {
            ...funcionario,
            horaDoPonto
          }
        })
        console.log('funcionariosComHorarios:', funcionariosComHorarios)
        // Filtra apenas funcionários ativos
        setFuncionariosPresentes(funcionariosComHorarios.filter(f => f.ativo))

        // setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar funcionários:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar funcionários')
        setShowAlert(true)
        //  setLoading(false)
      }
    }

    fetchFuncionarios()
  }, [RotaBase, tenantId])

  useEffect(() => {
    const loadModels = async () => {
      try {
        await loadFaceApiModels() // Carrega os modelos usando a função reutilizável
        setModelsLoaded(true) // Define que os modelos foram carregados
      } catch (error) {
        console.error('Erro ao carregar os modelos do face-api:', error)
        setModelsLoaded(false)
      }
    }

    loadModels()
  }, [])

  useEffect(() => {
    const autoFaceDetection = async () => {
      if (!autoCapture || !modelsLoaded || !webcamRef.current) return

      try {
        const imageSrc = webcamRef.current.getScreenshot()
        if (!imageSrc) return // Garante que há uma imagem válida

        const img = new Image()
        img.src = imageSrc
        img.onload = async () => {
          const detections = await faceapi
            .detectSingleFace(img)
            .withFaceLandmarks()
            .withFaceDescriptor()

          if (detections) {
            const descriptor = detections.descriptor

            // Tenta identificar automaticamente
            handleIdentifyFace(descriptor)
          }
        }
      } catch (error) {
        console.error('Erro ao capturar face automaticamente:', error)
      }
    }

    // Inicia a execução automática
    if (autoCapture) {
      const intervalId = setInterval(autoFaceDetection, 3000)

      return () => clearInterval(intervalId) // Limpa o intervalo ao desmarcar o checkbox
    }
  }, [autoCapture, modelsLoaded, handleIdentifyFace])

  // crie um useefect para monototar o valor de showAlert, se ele estiver como false tem que setar o valor de funcionarioIdentificado para false
  useEffect(() => {
    if (!showAlert) {
      setFuncionarioIdentificado(null)
    }
  }, [showAlert])
  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false)
      }, 4000) // 4 segundos

      // Limpa o timeout anterior, caso o estado mude antes
      return () => clearTimeout(timeout)
    }
  }, [showAlert])

  useEffect(() => {
    let ausentes = todosFuncionarios.filter(f => !funcionariosPresentes.some(p => p._id === f._id))

    setFuncionariosAusentes(ausentes)
    // set o total de funcionarios presentes com setTotalFuncionariosPresentes, para isso tem que agrupar os funcionarios presentes por _id e depois pegar o tamanho do array
    setTotalFuncionariosPresentes(
      Object.keys(
        funcionariosPresentes.reduce((acc, curr) => {
          acc[curr._id] = curr
          return acc
        }, {})
      ).length
    )

    // setSituacaoAnterior(situacaoAnterior)
  }, [todosFuncionarios, funcionariosPresentes])

  useEffect(() => {
    const handleKeyPress = event => {
      if (event.code === 'Space' && funcionarioIdentificado) {
        event.preventDefault() // Previne o comportamento padrão
        handleMarcarPresenca(funcionarioIdentificado)
      }
    }

    // Adiciona o evento de teclado ao montar o componente
    window.addEventListener('keydown', handleKeyPress)

    // Remove o evento de teclado ao desmontar o componente
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [funcionarioIdentificado, handleMarcarPresenca])

  const handleShowHorarioModal = registroSituacao => {
    setSelectedFuncionario({
      ...registroSituacao, // Inclui todos os dados do registro
      situacaoId: registroSituacao.situacaoId // Armazena o ID da situação corretamente
    })
    setShowModal(true)
  }

  const handleCloseHorarioModal = () => {
    setShowModal(false)
    setSelectedFuncionario(null)
  }

  const titulo = `Relogio de ponto${
    origem && origem.toLowerCase().includes('corte') ? ' - corte' : ''
  }`

  const handleSaveFace = async descriptor => {
    try {
      const url = `${config.host}/funcionariosAtivos`
      const dados = {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'gravarLeituraFacial',
        funcionarioId: selectedFuncionarioIdForFace,
        descriptor
      }

      const response = await fetchData(url, dados)

      if (response.message) {
        setAlertVariant('success')
        setAlertMessage('Leitura facial salva com sucesso!')
        setShowAlert(true)
      }
    } catch (error) {
      console.error('Erro ao salvar leitura facial:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao salvar leitura facial')
      setShowAlert(true)
    }
  }

  const autoCaptureRef = useRef(null)

  const obterSituacaoAnterior = (funcionariosPresentes, funcionarioId, dataAtual) => {
    // Verifica se o array foi fornecido
    if (!funcionariosPresentes || funcionariosPresentes.length === 0) {
      return 'Sem histórico'
    }

    // Filtra os registros do funcionário específico
    const registrosFuncionario = funcionariosPresentes.filter(
      registro => registro._id === funcionarioId
    )

    if (!registrosFuncionario || registrosFuncionario.length === 0) {
      return 'Sem histórico'
    }

    // Ordena os registros por data em ordem crescente
    const registrosOrdenados = registrosFuncionario.sort(
      (a, b) => new Date(a.data) - new Date(b.data)
    )

    // Encontra o índice do registro atual com base na data
    const indexAtual = registrosOrdenados.findIndex(
      registro => new Date(registro.data).getTime() === new Date(dataAtual).getTime()
    )

    // Se o índice atual for inválido ou não houver registros anteriores, retorna 'Sem histórico'
    if (indexAtual <= 0) {
      return 'Sem histórico'
    }
    const registroanterior = registrosOrdenados[indexAtual - 1] || 'Sem situação anterior'

    // Retorna a situação do registro imediatamente anterior
    return registroanterior
  }

  const handleCreateSituacao = novoRegistro => {
    // Atualizar apenas o selectedFuncionario para refletir o novo registro
    setSelectedFuncionario(prev => ({
      ...prev,
      data: novoRegistro.data,
      situacao: novoRegistro.situacao
    }))
  }

  return (
    <Container fluid className="p-4">
      {/* Título principal */}
      <div className="d-flex justify-content-center mb-4 align-items-center">
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Banner title={titulo} width="auto" fontSize={57} classEfeito3dMouseOver="" />
        </div>
      </div>

      <Row>
        {/* Bloco de Presenças */}
        <Col md={4}>
          <Banner
            title={`Presenças (${totalFuncionariosPresentes})`}
            width="100%"
            fontSize={47}
            classEfeito3dMouseOver=""
            color="#28a745"
          />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Colaborador</th>
                <th>Hora</th>
                <th>Situação</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {funcionariosPresentes?.map(funcionario => (
                <tr key={funcionario.data || funcionario._id}>
                  <td>{funcionario.Nome}</td>
                  <td>{funcionario.horaDoPonto || '--:--'}</td>
                  <td>{funcionario.situacao || 'N/A'}</td>
                  <td style={{ width: '90px' }}>
                    <Button variant="primary" onClick={() => handleShowHorarioModal(funcionario)}>
                      Mudar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <AlterarSituacaoModal
            show={showModal}
            onHide={handleCloseHorarioModal}
            onSave={situacao => console.log('Nova situação:', situacao)}
            onCreate={handleCreateSituacao}
            onDelete={handleDeleteSituacao}
            situacaoAtual={selectedFuncionario?.situacao}
            todasAsSituacoes={todasAsSituacoes}
            obterSituacaoAnterior={obterSituacaoAnterior}
            selectedFuncionario={selectedFuncionario}
            funcionariosPresentes={funcionariosPresentes}
          />
        </Col>

        {/* Bloco de Leitura Facial */}
        <Col md={4} className="d-flex flex-column align-items-center">
          <h2>Olhe para a Câmera</h2>
          {showAlert && (
            <div
              style={{
                position: 'fixed',
                top: '22%',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1050
              }}
            >
              <ShowAlertGeneric
                showAlert={showAlert}
                message={alertMessage}
                variant={alertVariant}
                setShowAlert={setShowAlert}
                timeOut={4000}
                isBlurred={true}
              />
            </div>
          )}
          <WebcamRecognition
            onCapture={handleSaveFace}
            onIdentify={handleIdentifyFace}
            autoCapture={autoCapture}
            selectedFuncionarioId={selectedFuncionarioIdForFace}
            cadastrarFuncionario={false}
          />
          <Form.Check
            ref={autoCaptureRef}
            type="checkbox"
            label="Leitura facial automática"
            checked={autoCapture}
            onChange={() => setAutoCapture(!autoCapture)}
            style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '1rem' }}
          />
          {funcionarioIdentificado && (
            <Button
              variant="success"
              onClick={() => handleMarcarPresenca(funcionarioIdentificado)}
              disabled={!habilitarBotaoPresenca}
              style={{ marginTop: '10px' }}
            >
              Marcar Presença
            </Button>
          )}
        </Col>

        {/* Bloco de Ausentes e Mais Opções */}
        <Col md={4} className="d-flex gap-2 position-relative">
          {/* Ausentes */}
          <div
            className="small-box"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '10px',
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: mostrarAusentes ? '#f9f9f9' : '#f0f0f0',
              transition: 'box-shadow 0.3s',
              width: '70px',
              position: 'relative',
              alignSelf: 'flex-start'
            }}
          >
            A ({funcionariosAusentes.length})
            {mostrarAusentes && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '0',
                  width: '300px',
                  backgroundColor: '#fff',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  zIndex: 10,
                  marginTop: '1px'
                }}
              >
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Ausentes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {funcionariosAusentes?.map(funcionario => (
                      <tr key={funcionario.data || funcionario._id}>
                        <td>{funcionario.Nome}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>

          {/* Mais opções */}
          <div
            className="opcoes-container"
            onMouseEnter={() => setMostrarSubmenu(true)}
            onMouseLeave={() => setMostrarSubmenu(false)}
            style={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '10px',
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: '#f9f9f9',
              transition: 'box-shadow 0.3s',
              width: '200px',
              position: 'relative',
              alignSelf: 'flex-start'
            }}
          >
            Mais opções
            {mostrarSubmenu && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '0',
                  width: '200px',
                  backgroundColor: '#fff',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  zIndex: 10,
                  marginTop: '1px'
                }}
              >
                <Button
                  variant="info"
                  onClick={() => navigate('/justificativas-faltas')}
                  style={{ margin: '6px', width: '90%' }}
                >
                  Cadastrar Justificativas de Faltas
                </Button>

                <button
                  style={{
                    display: 'block',
                    padding: '10px',
                    textDecoration: 'none',
                    color: '#333',
                    fontSize: '14px',
                    border: 'none',
                    background: 'none',
                    textAlign: 'left',
                    width: '100%',
                    borderBottom: '1px solid #ddd',
                    cursor: 'pointer'
                  }}
                >
                  Opção 2
                </button>
                <button
                  style={{
                    display: 'block',
                    padding: '10px',
                    textDecoration: 'none',
                    color: '#333',
                    fontSize: '14px',
                    border: 'none',
                    background: 'none',
                    textAlign: 'left',
                    width: '100%',
                    cursor: 'pointer'
                  }}
                >
                  Opção 3
                </button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ConfigurarFuncionariosAtivos
