//justificativas-faltas.jsx
import { useEffect, useState, useContext } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AuthContext } from '../../../../../src/context/AuthContext.jsx'
import { Container, Table, Button, Form, Modal, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { fetchData } from '../../../../../src/backend/model.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { config } from '../../../../../src/backend/configConexaoGeral'
import JustificativaModal from './JustificativaModal'
import { ConfirmModal } from '../../../../../src/components/pages/RELOGIODEPONTO/GerenteOperacoes/gerenteOperacoes.jsx'
const JustificativasFaltas = () => {
  const [justificativas, setJustificativas] = useState([])
  const [dataIntervalo, setDataIntervalo] = useState([new Date(), new Date()])
  const [funcionarios, setFuncionarios] = useState([])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [mostrarOpcoes, setMostrarOpcoes] = useState(false)
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [novaJustificativa, setNovaJustificativa] = useState({
    funcionarioId: null,
    data: '',
    motivo: '',
    documento: '',
    observacoes: ''
  })
  const [showAlert, setShowAlert] = useState(false) // Controla a visibilidade do alerta
  const [alertMessage, setAlertMessage] = useState('') // Armazena a mensagem a ser exibida
  const [alertVariant, setAlertVariant] = useState('') // Define o tipo de alerta (ex.: 'success', 'danger')

  const [justificativaSelecionada, setJustificativaSelecionada] = useState(null) // Justificativa em edição
  const [showConfirm, setShowConfirm] = useState(false)
  const [selectedJustificativaId, setSelectedJustificativaId] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchJustificativas = async () => {
      try {
        setLoading(true)
        const url = `${config.host}/justificativasFaltas`
        console.log('dataIntervalo[0]', dataIntervalo[0])

        // Normaliza as datas para o horário local antes de enviar
        const dataInicio = new Date(
          dataIntervalo[0].getFullYear(),
          dataIntervalo[0].getMonth(),
          dataIntervalo[0].getDate()
        )
        const dataFim = new Date(
          dataIntervalo[1].getFullYear(),
          dataIntervalo[1].getMonth(),
          dataIntervalo[1].getDate()
        )

        const dados = {
          requisicao: 'listarJustificativasPorPeriodo',
          tenantId,
          dataInicio: dataInicio.toISOString(), // Envia em ISO formatado
          dataFim: dataFim.toISOString() // Envia em ISO formatado
        }
        console.log('dados listagem de justificativas', dados)
        const response = await fetchData(url, dados)
        console.log('response justificativas', response)
        setJustificativas(response || [])
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar justificativas:', error)
        setLoading(false)
      }
    }

    const fetchFuncionarios = async () => {
      try {
        const url = `${config.host}/gerenciarSetores`
        const dados = {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionarios'
        }

        const response = await fetchData(url, dados)

        const options = response.map(funcionario => ({
          value: funcionario._id,
          label: funcionario.Nome
        }))
        setFuncionarios(options)
      } catch (error) {
        console.error('Erro ao buscar funcionários:', error)
      }
    }

    fetchFuncionarios()
    fetchJustificativas()
  }, [dataIntervalo, RotaBase, tenantId])

  const handleSalvarEdicao = async justificativa => {
    try {
      const url = `${config.host}/justificativasFaltas`

      // Criação do objeto 'dados' com os campos necessários
      const dados = {
        requisicao: justificativaSelecionada ? 'editarJustificativa' : 'criarJustificativa',
        justificativaId: justificativaSelecionada
          ? justificativaSelecionada?._id
          : justificativa.id || '', // Ajuste para edição
        funcionarioId: justificativa.funcionarioId,
        data: justificativa.data,
        motivo: justificativa.motivo,
        status: justificativa.status || 'Pendente',
        observacoes: justificativa.observacoes || '',
        tenantId, // Obtido do contexto ou configuração
        rota_base: RotaBase // Obtido do contexto ou configuração
      }

      // Adicionar documento apenas se ele for definido
      if (justificativa.documento && justificativa.documento !== 'undefined') {
        dados.documento = justificativa.documento
      }

      const response = await fetchData(url, dados)

      if (response) {
        setAlertVariant('success')
        setAlertMessage(
          justificativaSelecionada
            ? 'Justificativa editada com sucesso!'
            : 'Justificativa criada com sucesso!'
        )
        setShowAlert(true)

        // Atualizar a lista de justificativas
        if (justificativaSelecionada) {
          setJustificativas(prev =>
            prev.map(j =>
              j._id === justificativaSelecionada._id
                ? {
                    ...j,
                    ...response.justificativa,
                    funcionarioId: {
                      ...j.funcionarioId, // Mantém os dados populados
                      Nome: justificativa.funcionarioId?.Nome || j.funcionarioId?.Nome
                    }
                  }
                : j
            )
          )
        } else {
          setJustificativas(prev => [...prev, response.justificativa])
        }
        // Fecha o modal após salvar
        setShowModal(false)
        setJustificativaSelecionada(null) // Reseta o estado de edição
      }
    } catch (error) {
      console.error('Erro ao salvar justificativa:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao salvar justificativa.')
      setShowAlert(true)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false) // Fecha o modal
    setJustificativaSelecionada(null) // Reseta o estado de edição
  }

  // const handleExcluirJustificativa = async justificativaId => {
  //   if (!window.confirm('Tem certeza que deseja excluir esta justificativa?')) {
  //     return // Cancela a exclusão se o usuário não confirmar
  //   }

  //   try {
  //     const url = `${config.host}/justificativasFaltas`
  //     const dados = {
  //       requisicao: 'excluirJustificativa',
  //       justificativaId
  //     }

  //     const response = await fetchData(url, dados)

  //     if (response?.message) {
  //       setAlertVariant('success')
  //       setAlertMessage('Justificativa excluída com sucesso!')
  //       setShowAlert(true)

  //       // Atualiza a lista de justificativas no estado
  //       setJustificativas(prev => prev.filter(j => j._id !== justificativaId))
  //     }
  //   } catch (error) {
  //     console.error('Erro ao excluir justificativa:', error)
  //     setAlertVariant('danger')
  //     setAlertMessage('Erro ao excluir justificativa.')
  //     setShowAlert(true)
  //   }
  // }
  const confirmDelete = async () => {
    try {
      const url = `${config.host}/justificativasFaltas`
      const dados = {
        requisicao: 'excluirJustificativa',
        justificativaId: selectedJustificativaId
      }

      const response = await fetchData(url, dados)

      if (response?.message) {
        setAlertVariant('success')
        setAlertMessage('Justificativa excluída com sucesso!')
        setShowAlert(true)

        // Atualiza a lista de justificativas no estado
        setJustificativas(prev => prev.filter(j => j._id !== selectedJustificativaId))
      }
    } catch (error) {
      console.error('Erro ao excluir justificativa:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao excluir justificativa.')
      setShowAlert(true)
    } finally {
      setShowConfirm(false) // Fecha o modal
      setSelectedJustificativaId(null) // Reseta o ID selecionado
    }
  }

  const cancelDelete = () => {
    setShowConfirm(false)
    setSelectedJustificativaId(null)
  }

  return (
    <Container>
      <h1>Gerenciamento de Justificativas de Faltas</h1>
      <div style={{ marginBottom: '20px' }}>
        <label>Selecione o intervalo de datas:</label>
        <DatePicker
          selected={dataIntervalo[0]}
          onChange={dates => setDataIntervalo(dates)}
          startDate={dataIntervalo[0]}
          endDate={dataIntervalo[1]}
          selectsRange
          isClearable
          dateFormat="dd/MM/yyyy"
          placeholderText="Selecione o intervalo de datas"
        />
      </div>
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
      <ConfirmModal
        show={showConfirm}
        message="Tem certeza que deseja excluir esta justificativa?"
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Nova Justificativa
        </Button>

        <div
          className="opcoes-container"
          onMouseEnter={() => setMostrarOpcoes(true)}
          onMouseLeave={() => setMostrarOpcoes(false)}
          style={{
            padding: '10px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            border: '1px solid #ddd',
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            width: '220px',
            left: '125%'
          }}
        >
          Mais Opções
          {mostrarOpcoes && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                right: '0',
                width: '200px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                zIndex: 10
              }}
            >
              <Button
                variant="info"
                onClick={() => navigate('/funcionarios-ativos')}
                style={{ margin: '6px', width: '90%' }}
              >
                Bater Ponto
              </Button>
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Funcionário</th>
              <th>Data</th>
              <th>Motivo</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {justificativas.map(justificativa => (
              <tr key={justificativa._id}>
                <td>{justificativa.funcionarioId?.Nome || 'N/A'}</td>
                <td>
                  {new Date(justificativa.data).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    timeZone: 'UTC'
                  })}
                </td>

                <td>{justificativa.motivo}</td>
                <td>{justificativa.status}</td>
                <td>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setJustificativaSelecionada(justificativa) // Passa a justificativa selecionada
                      setShowModal(true) // Abre o modal
                    }}
                  >
                    Editar
                  </Button>

                  <Button
                    variant="danger"
                    onClick={() => {
                      setSelectedJustificativaId(justificativa._id)
                      setShowConfirm(true)
                    }}
                  >
                    Excluir
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <JustificativaModal
        show={showModal}
        onHide={handleCloseModal}
        onSave={handleSalvarEdicao}
        funcionarios={funcionarios}
        justificativaSelecionada={justificativaSelecionada}
      />
    </Container>
  )
}

export default JustificativasFaltas
