/**
 * Nota Importante:
 * Sempre que um novo módulo for criado, é necessário seguir as seguintes regras:
 *
 * 1. **Regra 1 (Adicionar um bloco condicional após a linha 101):**
 *    Verifique se o caminho atual (`pathname`) contém o nome do novo módulo e defina a variável `setTipoServico` de forma adequada.
 *    Exemplo:
 *    ```javascript
 *    else if (location.pathname.includes('relogiodeponto')) {
 *        setTipoServico('relogiodeponto');
 *    }
 *    ```
 *    - Certifique-se de que o nome utilizado no `includes` corresponde exatamente ao nome do módulo no caminho.
 *    - O valor passado para `setTipoServico` deve estar alinhado com o restante do sistema.
 *
 * 2. **Regra 2 (Adicionar um bloco condicional após a linha 120):**
 *    Crie um novo `if else` para carregar clientes ou dados relacionados ao novo módulo, semelhante ao exemplo abaixo:
 *    ```javascript
 *    else if (tipoServico === 'modelagem') {
 *        fetchedClientes = await carregarClientesDoServico('1', tenantId, RotaBase);
 *    }
 *    ```
 *    - Substitua `'modelagem'` pelo nome do novo módulo.
 *    - Certifique-se de passar os parâmetros corretos para a função `carregarClientesDoServico` ou equivalente.
 *
 * 3. **Regra 3 (Adicionar um bloco de código após a linha 338):**
 *    Adicione um `if` para tratar o novo módulo na lógica existente, conforme o exemplo abaixo:
 *    ```javascript
 *    if (props.isRelogiodeponto) {
 *        fetchedClientes = await carregarClientesDoServico('9', tenantId, RotaBase);
 *    }
 *    ```
 *    - Substitua `props.isRelogiodeponto` pela verificação do novo módulo.
 *    - Certifique-se de usar o ID correto para o serviço (campo 'id' da collection `servicos`).
 *
 * 4. **Regra 4 (Adicionar uma verificação na função `btnInput`):**
 *    No `if` dentro da função `btnInput`, adicione a verificação para o novo módulo.
 *    Exemplo:
 *    ```javascript
 *    if (props.isModelagem || props.isCorte || props.isPlotagem || props.isRelogiodeponto) {
 *        // Lógica adicional aqui
 *    }
 *    ```
 *    - Substitua ou adicione a nova condição para verificar o novo módulo corretamente.
 *
 * Seguindo essas quatro regras, o novo módulo será integrado corretamente ao sistema.
 */

import { useState, useEffect, useRef } from 'react'
import { Navbar, Button, Table, Form, Modal } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { carregarClientesDoServico } from '../../../../src/servicos/dadosServicos'
import { formatDate } from '../../../../src/utils/date-utils'
import { CarregaPrecosModelagem } from '../../../../src/components/pages/MODELAGEM/cadastro-precos-modelagem'
import { remove } from 'diacritics'
import diacritics from 'diacritics'
import { CarregaCategorias } from '../../../../src/components/pages/CadastroProduto/cadastro-produto'
import { CarregaGrausDeDificuldade } from '../../../../src/components/pages/MODELAGEM/cadastro-precos-modelagem'
import { useNavigate } from 'react-router-dom'
import AlertMessage from '../../../../src/components/pages/PLOTAGEM/AlertMessage'
import { pegaDado } from '../../../../src/utils/form-utils'
import { aplanarArray } from '../../../../src/backend/model'
import { CustomField } from '../../../../src/components/CustomField/custom-field'
import { AuthContext } from '../../../../src/context/AuthContext'
import { fetchData } from '../../../../src/backend/model'
import { config } from '../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../src/utils/form-utils.jsx'
import { useContext } from 'react'
function CadastroGeralPE(props) {
  const location = useLocation()
  const [isCarregado, setIsCarregado] = useState(false) // Estado para verificar se os dados foram carregados
  const [tipoServico, setTipoServico] = useState('') // Estado para determinar qual tipo de serviço carregar
  const { maxHeight = 900 } = props
  const [clientesDaPlotagem, setClientesDaPlotagem] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [dados, setDados] = useState([])
  const [filteredDados, setFilteredDados] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [clienteEditando, setClienteEditando] = useState(null)
  const [exibirFormularioEdicao, setExibirFormularioEdicao] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [categorias, set_categorias] = useState([])
  const [graus, set_graus] = useState([])
  const prevIsPlotagemRef = useRef()
  const navigate = useNavigate()
  const [editableTotals, setEditableTotals] = useState({})
  const [selectedItems, setSelectedItems] = useState([]) // parei aqui, preciso pegar os valores desse estado no componente pai
  // const [IntemsTravadosNaPesquisa, setIntemsTravadosNaPesquisa] = useState([]);
  const [editableTotal, setEditableTotal] = useState('')
  const pesquisaSetadaRef = useRef(false)
  const [calculatedTotals, setCalculatedTotals] = useState({})
  const { RotaBase, tenantId } = useContext(AuthContext)
  let colunas
  const [ItensSelecionadosParaGravarInterno, setItensSelecionadosParaGravarInterno] = useState([])
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [variantAlert, setVariantAlert] = useState('success')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [clienteToDelete, setClienteToDelete] = useState(null)

  const defaultTotals = {}

  // Função para realizar a pesquisa
  const realizarPesquisa = valor => {
    if (valor) {
      btnInput({ target: { value: valor } }) // Dispara a função de busca com o valor de pesquisa
    }
  }
  useEffect(() => {
    if (!pesquisaSetadaRef.current) {
      const params = new URLSearchParams(location.search)
      const pesquisa = params.get('pesquisa')

      if (pesquisa) {
        setEditableTotal(pesquisa)

        // Define o tipo de serviço baseado no pathname da URL
        if (location.pathname.includes('corte')) {
          setTipoServico('corte')
        } else if (location.pathname.includes('plotagem')) {
          setTipoServico('plotagem')
        } else if (location.pathname.includes('modelagem')) {
          setTipoServico('modelagem')
        } else if (location.pathname.includes('relogiodeponto')) {
          setTipoServico('relogiodeponto')
        }

        pesquisaSetadaRef.current = true // Marca como já definido para evitar execução repetida
      }
    }
  }, [location.search])

  useEffect(() => {
    async function fetchData() {
      if (!tipoServico) return

      setIsLoading(true) // Ative o estado de carregamento
      let fetchedClientes
      console.log('tipoServico', tipoServico)
      if (tipoServico === 'plotagem') {
        fetchedClientes = await carregarClientesDoServico('4', tenantId, RotaBase)
      } else if (tipoServico === 'corte') {
        fetchedClientes = await carregarClientesDoServico('3', tenantId, RotaBase)
      } else if (tipoServico === 'modelagem') {
        fetchedClientes = await carregarClientesDoServico('1', tenantId, RotaBase)
      } else if (tipoServico === 'relogiodeponto') {
        fetchedClientes = await carregarClientesDoServico('9', tenantId, RotaBase)
      }

      setClientesDaPlotagem(function () {
        const clientes = fetchedClientes.map(cliente => [
          cliente.id,
          cliente.data,
          cliente.nome,
          cliente.empresa,
          cliente.telefone,
          cliente.sobrenome,
          cliente.cpf,
          cliente.tipoPessoa,
          cliente.sexo,
          cliente.email,
          cliente.cep,
          cliente.endereco,
          cliente.numero,
          cliente.complemento,
          cliente.bairro,
          cliente.cidade,
          cliente.uf,
          cliente.cnpj
        ])

        return clientes
      })
      setIsLoading(false) // Desative o estado de carregamento após carregar os clientes
      setIsCarregado(true) // Marque como carregado
    }

    fetchData()
  }, [tipoServico, tenantId, RotaBase])

  // useEffect para disparar a pesquisa quando `editableTotal` for alterado
  useEffect(() => {
    if (pesquisaSetadaRef.current && editableTotal && isCarregado) {
      realizarPesquisa(editableTotal)
    }
  }, [editableTotal, isCarregado])

  if (!props.isCadastroServicoModelagem) {
    colunas = [
      'id',
      'data',
      'nome',
      'empresa',
      'telefone',
      'sobrenome',
      'cpf',
      'tipoPessoa',
      'sexo',
      'email',
      'cep',
      'endereco',
      'numero',
      'complemento',
      'bairro',
      'cidade',
      'uf',
      'cnpj'
    ]
  }

  useEffect(() => {
    {
      props.editableTotals && props.setEditableTotals(editableTotals)
    }
  }, [editableTotals])
  useEffect(() => {
    if (props.dados && props.dados.length > 0) {
      setDados(props.dados)
      //props.setDados(props.dados);
    }
  }, [props.dados])
  function pegarIdsObj(ObjConvertido) {
    const newObj = ObjConvertido.map(obj => [
      obj.id,
      obj.data,
      pegaDado(categorias, obj.categoria, 'id_categoria', 'nome_categoria'),
      pegaDado(graus, obj.grau, 'id', 'grau'),
      obj.descricao,
      obj.preco
    ])

    return newObj
  }

  function convertArrayToObject(array) {
    return [
      {
        id: array[0],
        data: array[1],
        categoria: array[2],
        grau: array[3],
        descricao: array[4],
        preco: array[5]
      }
    ]
  }
  //-----------------------------------------------------------------
  function convertArrayOfArraysToObject(arrays) {
    return arrays.map(array => ({
      id: array[0],
      data: array[1],
      categoria: array[2],
      grau: array[3],
      descricao: array[4],
      preco: array[5]
    }))
  }
  //------------------------------------------------------------------
  function removeItemFromItensSelecionadosParaGravar(clienteId) {
    // Convertendo clienteId para string para evitar problemas com comparação de tipos
    const clienteIdStr = String(clienteId)

    const filteredArray = props.ItensSelecionadosParaGravar.map(
      subArray => subArray.filter(item => String(item.id) !== clienteIdStr) // Convertendo item.id para string
    ).filter(subArray => subArray.length !== 0)

    return filteredArray
  }

  useEffect(() => {
    if (props.ItensSelecionadosParaGravar)
      props.setItensSelecionadosParaGravar(aplanarArray(ItensSelecionadosParaGravarInterno))
  }, [ItensSelecionadosParaGravarInterno])

  //--------------------------------------------------------------------------------

  function handleCheckboxChange(
    index, // você pode passar o índice atual aqui
    linha, // numero do registro
    ItensSelecionadosParaGravar,
    isSelected
  ) {
    const preco = ItensSelecionadosParaGravar[colunas.indexOf('preco')]
    let idRegistro = ItensSelecionadosParaGravar[0]
    // Se não existir, cria a variável de estado para a linha com o valor padrão de 1

    if (editableTotals[idRegistro] === undefined) {
      setEditableTotals(prevEditableTotals => ({
        ...prevEditableTotals,
        [idRegistro]: 1
      }))
    }

    const unidades = editableTotals[linha] !== undefined ? editableTotals[linha] : 1

    if (isSelected) {
      setSelectedItems(prev => {
        return [...prev, index]
      })

      props.setIntemsTravadosNaPesquisa(prev => {
        return [...prev, idRegistro]
      })

      const total = preco * unidades // Calcula o total

      setCalculatedTotals(prevTotals => ({
        ...prevTotals,
        [index]: total
      }))

      // Código adicionado para ItensSelecionadosParaGravar
      let ObjConvertido = convertArrayToObject(ItensSelecionadosParaGravar)
      let nb = pegarIdsObj(ObjConvertido)
      let nbc = convertArrayOfArraysToObject(nb)

      nbc[0]['Total'] = total // Adicionando a propriedade 'Total' ao objeto
      nbc[0]['unidade'] = unidades // Adicionando a propriedade 'unidade' ao objeto

      props.setItensSelecionadosParaGravar(prev => [...prev, nbc]) // aqui é onde é atualizado o modulo de totais gerais
    } else {
      setSelectedItems(prev => prev.filter(item => item !== index))

      props.setIntemsTravadosNaPesquisa(prev => prev.filter(item => item !== idRegistro))

      setCalculatedTotals(prevTotals => {
        const newTotals = { ...prevTotals }

        delete newTotals[index] // Isso irá remover o valor 'Total' para esse índice

        return newTotals
      })

      // Código adicionado para ItensSelecionadosParaGravar
      const newItensSelecionadosParaGravar = removeItemFromItensSelecionadosParaGravar(linha) // linha no caso aqui é o id do registro
      props.setItensSelecionadosParaGravar(newItensSelecionadosParaGravar)
    }
  }

  //--------------------------------------------------------------------------------

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true) // Ative o estado de carregamento
      let fetchedClientes, categorias, graus
      console.log('props', props)
      if (props.isPlotagem) {
        fetchedClientes = await carregarClientesDoServico('4', tenantId, RotaBase)
      }
      if (props.isCorte) {
        fetchedClientes = await carregarClientesDoServico('3', tenantId, RotaBase)
      }
      if (props.isModelagem) {
        fetchedClientes = await carregarClientesDoServico('1', tenantId, RotaBase)
      }
      if (props.isRelogiodeponto) {
        fetchedClientes = await carregarClientesDoServico('9', tenantId, RotaBase)
      }
      if (props.isCadastroServicoModelagem) {
        fetchedClientes = await CarregaPrecosModelagem()

        categorias = await CarregaCategorias()
        set_categorias(categorias)

        graus = await CarregaGrausDeDificuldade()
        set_graus(graus)
      }
      setIsLoading(false) // Desative o estado de carregamento após carregar os clientes

      if (!props.isCadastroServicoModelagem && clientesDaPlotagem && fetchedClientes) {
        setClientesDaPlotagem(function () {
          const clientes = fetchedClientes.map(cliente => [
            cliente.id,
            cliente.data,
            cliente.nome,
            cliente.empresa,
            cliente.telefone,
            cliente.sobrenome,
            cliente.cpf,
            cliente.tipoPessoa,
            cliente.sexo,
            cliente.email,
            cliente.cep,
            cliente.endereco,
            cliente.numero,
            cliente.complemento,
            cliente.bairro,
            cliente.cidade,
            cliente.uf,
            cliente.cnpj
          ])

          return clientes
        })
      }
      if (props.isCadastroServicoModelagem) {
        let clientes
        setClientesDaPlotagem(function () {
          clientes = fetchedClientes.map(cliente => [
            cliente.id,
            cliente.data,
            pegaDado(categorias, cliente.id_categoria, 'nome_categoria', 'id_categoria'),
            pegaDado(graus, cliente.id_grau, 'grau', 'id'),
            cliente.descricao,
            cliente.preco
          ])

          return clientes
        })
      }
    }

    fetchData()
  }, [props.isPlotagem, props.isCorte, props.isModelagem, props.isCadastroServicoModelagem])

  let value = ''
  function handleEditarClick(cliente) {
    const clienteId = cliente[colunas.indexOf('id')]

    const clienteData = cliente[colunas.indexOf('data')]

    let url
    if (props.isPlotagem) {
      url = `/cadastro-cliente-plotagem-editar/${clienteId}?pesquisa=${editableTotal}`
    } else if (props.isCorte) {
      url = `/cadastro-cliente-corte-editar/${clienteId}?pesquisa=${editableTotal}`
    } else if (props.isModelagem) {
      url = `/cadastro-cliente-modelagem-editar/${clienteId}?pesquisa=${editableTotal}`
    }

    navigate(url)
  }
  // //--------------------------------------------------------------------------------
  // Função para lidar com o clique no botão 'Visualizar'
  function handleVisualizarClick(cliente) {
    const clienteId = cliente[colunas.indexOf('id')]

    let url
    if (props.isPlotagem) {
      url = `/cadastro-cliente-plotagem-visualizar/${clienteId}`
    } else if (props.isCorte) {
      url = `/cadastro-cliente-corte-visualizar/${clienteId}`
    } else if (props.isModelagem) {
      url = `/cadastro-cliente-modelagem-visualizar/${clienteId}`
    }

    navigate(url)
  }
  function ResultSearch(inputText, indexsSearch, dados_p) {
    const searchInput = remove(inputText || '')
      .toLowerCase()
      .trim()
    // pegando tudo que esta digitado no input

    let normalizedInput = diacritics.remove(searchInput).replace(/[^a-z0-9\s]/g, '')
    let searchWords = normalizedInput.split(/\s+/) // cria um array das palavras digitadas no input, sendo o separador um ou mais espaços, so nao entendi ainda o motivo disso
    let searchColumnar = [...indexsSearch] // define em quais indices do array trazido do banco de dados sera feita a pesquisa
    const dadosCopy = [...clientesDaPlotagem] // cria uma nova cópia do array de dados // cria uma cópia do array de dados

    let resultsArray = dadosCopy
    // Realiza a busca apenas no resultado anterior, se o novo texto contém o anterior
    if (
      resultsArray &&
      resultsArray.length > 0 &&
      resultsArray.some(item => {
        // Verifica se item[2] é uma string e, se não for, trata como string vazia
        const itemValue = typeof item[2] === 'string' ? item[2].toLowerCase() : ''

        // Garante que searchInput é uma string antes de aplicar trim() e toLowerCase()
        const searchValue = (searchInput || '').trim().toLowerCase()

        return itemValue.includes(searchValue)
      })
    ) {
      resultsArray = resultsArray.filter(function (r) {
        return searchWords.every(function (wordCd) {
          return searchColumnar.some(function (colIndexCd) {
            // Verifica se o valor em r[colIndexCd] é definido antes de chamar toString
            const colValue = r[colIndexCd]
            if (colValue === undefined || colValue === null) return false

            const colValueCleaned = remove(colValue.toString()).toLowerCase()
            return colValueCleaned.indexOf(wordCd) !== -1
          })
        })
      })
    } else {
      // Realiza a busca em todo o array de dados

      resultsArray = dadosCopy.filter(function (r) {
        return searchWords.every(function (wordCd) {
          return searchColumnar.some(function (colIndexCd) {
            // Verifica se o valor em r[colIndexCd] é definido antes de chamar toString
            const colValue = r[colIndexCd]
            if (colValue === undefined || colValue === null) return false

            const colValueCleaned = remove(colValue.toString()).toLowerCase()
            return colValueCleaned.indexOf(wordCd) !== -1
          })
        })
      })
    }

    return resultsArray
  }
  //------------------------------------------------------------------------------------------
  function btnInput(e) {
    const inputValue = e.target.value // Pega o valor digitado como string

    setEditableTotal(inputValue)

    const value = e.target.value
    let resultsFromSearch = []

    // Se o input tiver um valor, obtenha os resultados da pesquisa.
    if (value) {
      resultsFromSearch = ResultSearch(
        value,
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
        props.initialData
      )
    }

    let combinedResults = []
    console.log('props.IntemsTravadosNaPesquisa', props.IntemsTravadosNaPesquisa)
    if (Array.isArray(props.IntemsTravadosNaPesquisa)) {
      console.log('resultsFromSearch', resultsFromSearch)
      resultsFromSearch.forEach(searchedItem => {
        if (
          !props.IntemsTravadosNaPesquisa.some(
            selectedId => selectedId === searchedItem[colunas.indexOf('id')]
          )
        ) {
          combinedResults.push(searchedItem)
        }
      })

      props.IntemsTravadosNaPesquisa.forEach(selectedId => {
        const selectedItem = clientesDaPlotagem.find(
          item => item[colunas.indexOf('id')] === selectedId
        )
        if (selectedItem) {
          console.log('selectedItem', selectedItem)
          combinedResults.push(selectedItem)
        }
      })
    } else {
      console.log('resultsFromSearch 1', resultsFromSearch)
      combinedResults.push(resultsFromSearch)
    }

    if (props.isModelagem || props.isCorte || props.isPlotagem || props.isRelogiodeponto) {
      setDados(combinedResults[0])
    } else {
      setDados(combinedResults)
      props.setDados(combinedResults)
    }
  }

  function removerAcentos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
  // let colunas;

  let larguraTablePesquisa = 'auto'
  if (props.isCadastroServicoModelagem) {
    colunas = ['id', 'data', 'id_categoria', 'id_grau', 'descricao', 'preco']
    larguraTablePesquisa = '120%'
  }
  let titleText

  if (props.isCadastroServicoModelagem) {
    titleText = 'LISTA DE MODELAGENS'
  } else {
    switch (location.pathname) {
      case '/plotagem-pesquisa-e-edicao-cliente':
        titleText = 'LISTA DE CLIENTES DA PLOTAGEM'
        break
      case '/corte-pesquisa-e-edicao-cliente':
        titleText = 'LISTA DE CLIENTES DO CORTE'
        break
      case '/modelagem-pesquisa-e-edicao-cliente':
        titleText = 'LISTA DE CLIENTES DA MODELAGEM'
        break
      default:
        titleText = 'LISTA DE CADASTROS'
        break
    }
  }
  //--------------------------------------------------------------------------------

  const handleEditableTotalChange = (linha, index, e, ItensSelecionadosParaGravar) => {
    // Obter o valor de entrada e converter para um número (use 0 como padrão se não for um número)
    const inputValue = parseFloat(e.target.value) || 0

    // Garantir que ItensSelecionadosParaGravar seja um array válido
    if (!Array.isArray(ItensSelecionadosParaGravar)) {
      console.error('ItensSelecionadosParaGravar não é um array válido')
      return
    }

    let idRegistro = ItensSelecionadosParaGravar[0]

    // Verificar se idRegistro é válido
    if (idRegistro === undefined || idRegistro === null) {
      console.error('idRegistro é undefined ou null')
      return
    }

    // Atualizar editableTotals
    setEditableTotals(prevEditableTotals => ({
      ...prevEditableTotals,
      [idRegistro]: inputValue
    }))

    // Calcular novo total se idRegistro corresponder ao índice
    if (ItensSelecionadosParaGravar[0].toString() === index.toString()) {
      const preco = parseFloat(ItensSelecionadosParaGravar[5]) || 0
      const newTotal = preco * inputValue

      // Atualizar CalculatedTotals para este índice
      setCalculatedTotals(prevTotals => ({
        ...prevTotals,
        [index]: newTotal
      }))

      // Atualizar ItensSelecionadosParaGravar com o novo valor
      let updatedItems = props.ItensSelecionadosParaGravar.map(subArray => {
        return subArray.map(item => {
          if (item.id === idRegistro) {
            return {
              ...item,
              unidade: inputValue,
              Total: newTotal
            }
          }
          return item
        })
      })

      props.setItensSelecionadosParaGravar(updatedItems)
    } else {
      console.error(`Não foi encontrado um item com o índice ${index} para atualizar.`)
    }
  }

  //--------------------------------------------------------------------------------
  function updateTotal(data, idRegistro, newTotal) {
    // Itera através dos arrays dentro do objeto de dados
    for (let i = 0; i < data.length; i++) {
      // Itera através dos objetos dentro de cada array
      for (let j = 0; j < data[i].length; j++) {
        // Verifica se o id do objeto atual é igual ao idRegistro fornecido
        if (data[i][j].id === idRegistro) {
          // Atualiza a chave Total do objeto encontrado com o valor de newTotal
          data[i][j].Total = newTotal
          // Retorna o objeto de dados atualizado (opcional)
          return data
        }
      }
    }
    // Retorna null se nenhum objeto correspondente for encontrado (opcional)
    return null
  }

  const atualizaTotalGeral = (
    linha,
    newTotal,
    selectedItems,
    inputValue,
    setItensSelecionadosParaGravar,
    ItensSelecionadosParaGravar,
    idRegistro
  ) => {
    // Verifique se o checkbox está marcado para essa linha

    if (selectedItems.includes(linha)) {
      // Copie o atual estado de ItensSelecionadosParaGravar
      let updatedItensSelecionadosParaGravar = [
        ...updateTotal(ItensSelecionadosParaGravar, idRegistro, newTotal)
      ]

      setItensSelecionadosParaGravar(updatedItensSelecionadosParaGravar)
    }
  }

  //--------------------------------------------------------------------------------

  const handleOnClear = (index, linha) => {
    // Limpar editableTotals

    setEditableTotals(prevTotals => {
      const newEditableTotals = { ...prevTotals }

      delete newEditableTotals[linha]
      return newEditableTotals
    })

    // Limpar CalculatedTotals (assumindo que você tem um estado chamado CalculatedTotals)
    setCalculatedTotals(prevTotals => {
      const newCalculatedTotals = { ...prevTotals }

      delete newCalculatedTotals[index]
      return newCalculatedTotals
    })

    // Desmarcar o checkbox correspondente
    props.setIntemsTravadosNaPesquisa(prevSelectedItems =>
      prevSelectedItems.filter(item => item !== linha)
    )

    const newItensSelecionadosParaGravar = removeItemFromItensSelecionadosParaGravar(linha) // linha no caso aqui é o id do registro
    props.setItensSelecionadosParaGravar(newItensSelecionadosParaGravar)
  }

  function handleClear() {
    // Define o valor de editableTotal como uma string vazia
    setEditableTotal('')

    // Cria um evento falso com um valor de target vazio para simular o campo CustomField vazio
    const fakeEvent = { target: { value: '' } }

    // Chama a função btnInput com o evento falso
    btnInput(fakeEvent)
  }

  function handleExcluirClick(cliente) {
    // const clienteId = cliente[colunas.indexOf('id')]

    // // Confirmar exclusão
    // if (window.confirm('Tem certeza que deseja excluir este cliente?')) {
    //   // Definir a URL para exclusão
    //   const dados = {
    //     tenantId: tenantId,
    //     rota_base: RotaBase,
    //     id: clienteId,
    //     requisicao: 'excluirCliente'
    //   }
    //   const url = config.host + '/funcoesGenericas'

    //   // Enviar requisição DELETE ao servidor
    //   fetchData(url, dados)
    //     .then(response => {
    //       if (response.ok) {
    //         // Atualizar a lista de clientes removendo o cliente excluído
    //         setDados(prevDados => prevDados.filter(c => c[colunas.indexOf('id')] !== clienteId))
    //         setAlertMessage('Cliente excluído com sucesso.')
    //         setVariantAlert('success')
    //         setShowAlertMessage(true)
    //       } else {
    //         setAlertMessage('Erro ao excluir cliente.')
    //         setVariantAlert('danger')
    //         setShowAlertMessage(true)
    //       }
    //     })
    //     .catch(error => {
    //       console.error('Erro ao excluir cliente:', error)
    //       alert('Erro ao excluir cliente.')
    //     })
    // }

    setClienteToDelete(cliente)
    setShowConfirmModal(true)
  }
  function confirmarExclusao() {
    if (!clienteToDelete) return

    const clienteId = clienteToDelete[colunas.indexOf('id')]

    // Definir a URL para exclusão
    const dados = {
      tenantId: tenantId,
      rota_base: RotaBase,
      id: clienteId,
      requisicao: 'excluirCliente'
    }
    const url = config.host + '/funcoesGenericas'

    // Enviar requisição DELETE ao servidor
    fetchData(url, dados)
      .then(response => {
        if (response.ok) {
          // Atualizar a lista de clientes removendo o cliente excluído
          setDados(prevDados => prevDados.filter(c => c[colunas.indexOf('id')] !== clienteId))

          // Atualize a mensagem do alerta
          setAlertMessage('Cliente excluído com sucesso.')
          setShowAlertMessage(true)
        } else {
          setAlertMessage('Erro ao excluir cliente.')
          setShowAlertMessage(true)
        }
      })
      .catch(error => {
        console.error('Erro ao excluir cliente:', error)
        setAlertMessage('Erro ao excluir cliente.')
        setShowAlertMessage(true)
      })
      .finally(() => {
        setShowConfirmModal(false)
        setClienteToDelete(null)
      })
  }

  return (
    <div style={{ width: larguraTablePesquisa }}>
      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        variant={variantAlert}
        isBlurred={true} // ou algum outro critério que deseja usar para blur
        setShowAlert={setShowAlertMessage}
      />
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação de Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza que deseja excluir este cliente?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Não
          </Button>
          <Button variant="danger" onClick={confirmarExclusao}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      <Navbar bg="light" className="paddinglefright">
        {!props.isCadastroServicoModelagem && (
          <Link to="/cadastro-geral" style={{ marginRight: '8px' }}>
            <Button>Cadastrar</Button>
          </Link>
        )}
        <div style={{ display: 'flex' }}>
          <CustomField
            label="pesquisa"
            placeholder="pesquisa"
            width="270px"
            value={editableTotal}
            disabled={isLoading}
            onChange={btnInput}
            onClear={handleClear}
            className={'Efeito3dMouseOver-class'}
            flex={1}
          />
          <Navbar.Brand
            id="text-head-form"
            className="label-lista"
            style={{ color: '#000', flex: 2, textAlign: 'center' }}
          >
            {titleText}
          </Navbar.Brand>
          {isLoading && (
            <div style={{ position: 'absolute', top: '-7px', left: '-7px' }}>
              <AlertMessage
                message="Preparando a busca"
                size="small"
                customHeight={39}
                customWidth={278}
                variant1="primary"
                variant2="light"
                variantDefault="success"
                isBlinking={true}
              />
            </div>
          )}
        </div>
      </Navbar>

      <div className="form-cadastro">
        <div className="form">
          <Table
            className="TR-table-custom striped bordered hover"
            style={{ width: '100%', tableLayout: 'fixed' }}
          >
            <thead className="CustomTable_thead">
              <tr className="TR-cadastro-geral-pesquisa-e-edicao">
                {!props.isCadastroServicoModelagem ? (
                  <>
                    <th style={{ width: '9%' }}>Data</th>
                    <th style={{ width: '10%' }}>Nome</th>
                    {/* <th style={{ width: '10%' }}>Sobrenome</th> */}
                    {/* <th style={{ width: '10%' }}>Empresa</th> */}
                    <th style={{ width: '12%' }}>Telefone</th>
                    <th style={{ width: '7%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      CPF
                    </th>
                    <th style={{ width: '8%' }}>Tipo Pessoa</th>
                    <th style={{ width: '5%' }}>Sexo</th>
                    <th style={{ width: '7%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      Email
                    </th>
                    <th style={{ width: '6%' }}>CEP</th>
                    <th style={{ width: '10%' }}>Endereço</th>
                    <th style={{ width: '5%' }}>Num.</th>
                    <th style={{ width: '8%' }}>Complem.</th>
                    <th style={{ width: '8%' }}>Bairro</th>
                    <th style={{ width: '8%' }}>Cidade</th>
                    <th style={{ width: '4%' }}>UF</th>
                    <th style={{ width: '8%' }}>CNPJ</th>
                    <th style={{ width: '12%' }}>Ações</th>
                  </>
                ) : (
                  <>
                    <th style={{ width: '5%' }}>#</th>
                    <th style={{ width: '10%' }}>Data</th>
                    <th style={{ width: '15%' }}>Categoria</th>
                    <th style={{ width: '10%' }}>Grau</th>
                    <th style={{ width: '25%' }}>Descrição</th>
                    <th style={{ width: '10%' }}>Preço</th>
                    <th style={{ width: '10%' }}>Unidades</th>
                    <th style={{ width: '10%' }}>Total</th>
                    <th style={{ width: '15%' }}>Confirmação</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody id="searchResults">
              {dados &&
                dados.map((cliente, index) => (
                  <tr
                    key={index}
                    className={
                      props.IntemsTravadosNaPesquisa &&
                      props.IntemsTravadosNaPesquisa.includes(cliente[0])
                        ? 'yellow-highlight'
                        : ''
                    }
                  >
                    {!props.isCadastroServicoModelagem ? (
                      <>
                        <td style={{ width: '6%' }}>
                          {formatDate(cliente[colunas.indexOf('data')]) || '-'}
                        </td>
                        <td style={{ width: '10%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {cliente[colunas.indexOf('nome')] || '-'}
                        </td>
                        {/* <td style={{ width: '10%' }}>
                          {cliente[colunas.indexOf('sobrenome')] || '-'}
                        </td> */}
                        {/* <td style={{ width: '10%' }}>
                          {cliente[colunas.indexOf('empresa')] || '-'}
                        </td> */}
                        <td style={{ width: '8%' }}>
                          {cliente[colunas.indexOf('telefone')] || '-'}
                        </td>
                        <td style={{ width: '7%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {cliente[colunas.indexOf('cpf')] || '-'}
                        </td>
                        <td style={{ width: '8%' }}>
                          {cliente[colunas.indexOf('tipoPessoa')] || '-'}
                        </td>
                        <td style={{ width: '5%' }}>
                          {cliente[colunas.indexOf('sexo')] === 'Feminino'
                            ? 'F'
                            : cliente[colunas.indexOf('sexo')] === 'Masculino'
                            ? 'M'
                            : cliente[colunas.indexOf('sexo')] || '-'}
                        </td>
                        <td style={{ width: '7%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {cliente[colunas.indexOf('email')] || '-'}
                        </td>
                        <td style={{ width: '6%' }}>{cliente[colunas.indexOf('cep')] || '-'}</td>
                        <td style={{ width: '10%' }}>
                          {cliente[colunas.indexOf('endereco')] || '-'}
                        </td>
                        <td style={{ width: '5%' }}>{cliente[colunas.indexOf('numero')] || '-'}</td>
                        <td style={{ width: '7%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {cliente[colunas.indexOf('complemento')] || '-'}
                        </td>
                        <td style={{ width: '7%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {cliente[colunas.indexOf('bairro')] || '-'}
                        </td>
                        <td style={{ width: '8%' }}>{cliente[colunas.indexOf('cidade')] || '-'}</td>
                        <td style={{ width: '4%' }}>{cliente[colunas.indexOf('uf')] || '-'}</td>
                        <td style={{ width: '7%', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                          {cliente[colunas.indexOf('cnpj')] || '-'}
                        </td>
                        <td style={{ width: '12%' }}>
                          <Button
                            variant="outline-warning"
                            size="sm"
                            onClick={() => handleEditarClick(cliente)}
                            style={{ margin: '2px', padding: '3px 6px' }}
                          >
                            Editar
                          </Button>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            style={{ margin: '2px', padding: '3px 6px' }}
                            onClick={() => handleExcluirClick(cliente)}
                          >
                            Excluir
                          </Button>
                          <Button
                            variant="outline-info"
                            size="sm"
                            style={{ margin: '2px', padding: '3px 6px' }}
                            onClick={() => handleVisualizarClick(cliente)}
                          >
                            Visualizar
                          </Button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td style={{ width: '5%' }}>{cliente[colunas.indexOf('id')] || '-'}</td>
                        <td style={{ width: '10%' }}>
                          {formatDate(cliente[colunas.indexOf('data')]) || '-'}
                        </td>
                        <td style={{ width: '15%' }}>
                          {cliente[colunas.indexOf('id_categoria')] || '-'}
                        </td>
                        <td style={{ width: '10%' }}>
                          {cliente[colunas.indexOf('id_grau')] || '-'}
                        </td>
                        <td style={{ width: '25%' }}>
                          {cliente[colunas.indexOf('descricao')] || '-'}
                        </td>
                        <td style={{ width: '10%' }}>
                          {cliente[colunas.indexOf('preco')]
                            ? Number(cliente[colunas.indexOf('preco')]).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            : '-'}
                        </td>
                        <td style={{ width: '10%' }}>
                          {props.IntemsTravadosNaPesquisa.includes(cliente[0]) ? (
                            <CustomField
                              type="number"
                              width="100px"
                              value={editableTotals[cliente[0]] || ''}
                              onClear={() => handleOnClear(index, cliente[colunas.indexOf('id')])}
                              onChange={e =>
                                handleEditableTotalChange(
                                  index,
                                  cliente[colunas.indexOf('id')],
                                  e,
                                  cliente
                                )
                              }
                            />
                          ) : (
                            <span>{editableTotals[cliente[0]] || '-'}</span>
                          )}
                        </td>
                        <td style={{ width: '10%' }}>
                          {Number(editableTotals[cliente[0]]) > 0
                            ? (
                                Number(cliente[colunas.indexOf('preco')]) *
                                Number(editableTotals[cliente[0]])
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            : selectedItems.includes(cliente[colunas.indexOf('id')]) &&
                              editableTotals[cliente[0]] === ''
                            ? Number(cliente[colunas.indexOf('preco')]).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            : '-'}
                        </td>
                        <td style={{ width: '15%' }}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check
                              type="checkbox"
                              label="Confirme a opção"
                              checked={props.IntemsTravadosNaPesquisa.includes(cliente[0])}
                              onChange={e =>
                                handleCheckboxChange(
                                  index,
                                  cliente[colunas.indexOf('id')],
                                  cliente,
                                  e.target.checked
                                )
                              }
                            />
                          </Form.Group>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default CadastroGeralPE
