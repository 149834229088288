// NavbarModelagemcompleta.jsx

import React, { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { AuthContext } from '../../../context/AuthContext'
// import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'
import logoModaNegocios360 from '../Usuarios/login/4.png'
import nomeModaNegocios360 from '../Usuarios/login/6.png'
import Banner from '../CadastroGeral/Banner'
import { fetchData } from '../../../backend/model' // Importação de fetchData
import { config } from '../../../backend/configConexaoGeral' // Importação de config
import PropTypes from 'prop-types'
//import CustomTooltip from './CustomTooltip' // Supondo que CustomTooltip esteja em um arquivo separado

export const CustomTooltip = ({
  children,
  setShowAlertMessage,
  setAlertMessage,
  manualTrigger = false, // Define se o controle será manual
  show, // Estado vindo do componente pai para controle manual
  onOpen, // Callback para quando o tooltip é aberto manualmente
  onClose, // Callback para quando o tooltip é fechado manualmente
  setCheckFace,
  setDonoLogado
}) => {
  const [internalShow, setInternalShow] = useState(false) // Controle interno do tooltip
  const [ip_atual, set_ip_atual] = useState('')
  const [ips, setIps] = useState([])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const tooltipRef = useRef(null) // Referência para o tooltip
  const navigate = useNavigate()
  const authContextValues = useContext(AuthContext)
  const { RotaBase, tenantId } = authContextValues

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Erro ao obter o IP:', error)
      }
    }
    fetchIP()
  }, [])

  const loadIps = async () => {
    try {
      const dados = {
        requisicao: 'RetornarTodosIps',
        rota_base: RotaBase,
        tenantId
      }
      const url = `${config.host}/liberar_ips`
      const response = await fetchData(url, dados)
      return response
    } catch (error) {
      console.error('Erro ao carregar os IPs:', error)
      return []
    }
  }

  const handleSubmit = async (username, password, ips, ip_atual, navigate, contextValues) => {
    const {
      login,
      logout,
      setIdNivelAcesso,
      setNivelAcesso,
      setUser,
      setSenha,
      setTenantId,
      setAcessoModulos,
      setNomeCliente,
      setEmail,
      setIdentificacaoFiscal
    } = contextValues

    try {
      const url = `${config.host}/login`
      const dados = {
        username: username.trim(),
        password,
        rota_base: RotaBase,
        requisicao: 'login'
      }

      const log = await fetchData(url, dados)

      if (log && log.success) {
        console.log('manualTrigger', manualTrigger)
        if (!manualTrigger) {
          setTenantId(log.user.tenantId)
          setIdNivelAcesso(log.user.id_nivel_acesso)
          setNivelAcesso(log.user.nivel_acesso)
          setUser(log.user.nome_usuario)
          setSenha(log.user.email)
          setAcessoModulos(log.user.acessoModulos)
          setNomeCliente(log.user.nome)
          setEmail(log.user.email)
          setIdentificacaoFiscal(log.user.indentificacaoFiscal)
          login()

          const loadedIps = await loadIps()
          setIps(loadedIps)

          const ipPermitido = loadedIps.some(ip => ip.ip_liberado === ip_atual)
          if (
            !ipPermitido &&
            log.user.nivel_acesso !== 'Administrador' &&
            log.user.nivel_acesso !== 'owner'
          ) {
            setAlertMessage('IP SEM PERMISSÃO DE ACESSO!')
            setShowAlertMessage(true)
            logout()
            return
          }

          setErrorMessage('')
          navigate('/inicio-mc')
        } else {
          setDonoLogado(true)
          console.log('entrei aqui')
          setCheckFace(true)
          setInternalShow(false)
          if (onClose) onClose()
        }
      } else if (log && !log.success) {
        const mensagemErro = log.message || 'Falha no login'
        setErrorMessage(mensagemErro)
        setAlertMessage(mensagemErro)
        setShowAlertMessage(true)
      } else {
        throw new Error('Falha no login')
      }
    } catch (error) {
      logout()
      const mensagemErroGeral = 'Erro ao tentar se conectar ao servidor.'
      setErrorMessage(mensagemErroGeral)
      setAlertMessage(error.message || mensagemErroGeral)
      setShowAlertMessage(true)
      console.error('Erro na autenticação:', error)
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault()
    handleSubmit(username, password, ips, ip_atual, navigate, authContextValues)
  }

  // Controle híbrido com temporizador
  useEffect(() => {
    if (manualTrigger) {
      if (show) {
        setInternalShow(true)
        if (onOpen) onOpen()
      } else {
        setTimeout(() => {
          setInternalShow(false)
          if (onClose) onClose()
        }, 2000) // Temporizador de 2000ms antes de fechar
      }
    }
  }, [manualTrigger, show, onOpen, onClose])

  const showTip = () => {
    if (!manualTrigger) setInternalShow(true)
  }

  const hideTip = () => {
    if (!manualTrigger) {
      setTimeout(() => {
        if (!tooltipRef.current.contains(document.activeElement)) {
          setInternalShow(false)
        }
      }, 2000)
    }
  }

  return (
    <div
      onMouseEnter={!manualTrigger ? showTip : undefined}
      onMouseLeave={!manualTrigger ? hideTip : undefined}
      style={{ position: 'relative', display: 'inline-block' }}
      ref={tooltipRef}
    >
      {children}
      {internalShow && (
        <div
          className="login-container-homeMC"
          onMouseEnter={showTip}
          onMouseLeave={hideTip}
          style={{
            position: 'absolute',
            top: manualTrigger && 0,
            left: manualTrigger ? '-150px' : '', // Ajuste a distância desejada aqui
            zIndex: 9999
          }}
        >
          {/* Mensagem condicional para manualTrigger */}
          {manualTrigger && (
            <div
              style={{
                backgroundColor: '#f0f8ff',
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#333'
              }}
            >
              Para acessar esta parte do sistema, por favor, faça login com as credenciais do
              proprietário.
            </div>
          )}
          <form className="login-formMC" onSubmit={handleFormSubmit}>
            <input
              className="inputsLoginMC"
              type="text"
              placeholder="Usuário ou e-mail"
              value={username}
              onChange={e => setUsername(e.target.value)}
              onFocus={showTip} // Mantém o tooltip aberto
              onBlur={hideTip} // Fecha somente quando perder o foco
            />
            <input
              className="inputsLoginMC"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onFocus={showTip} // Mantém o tooltip aberto
              onBlur={hideTip} // Fecha somente quando perder o foco
            />
            <a href="#" className="forgot-password">
              Esqueceu sua senha?
            </a>
            <button type="submit" className="login-button">
              Entrar
            </button>
          </form>
          {manualTrigger && (
            <button
              onClick={() => {
                setInternalShow(false) // Temporizador de 2000ms antes de fechar
                if (onClose) onClose()
              }}
              style={{
                marginTop: '10px',
                background: 'transparent',
                color: '#007bff',
                border: 'none',
                fontSize: '14px',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
            >
              Fechar
            </button>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      )}
    </div>
  )
}

CustomTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  setShowAlertMessage: PropTypes.func,
  setAlertMessage: PropTypes.func,
  manualTrigger: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  setCheckFace: PropTypes.bool,
  setDonoLogado: PropTypes.bool
}
export const NavbarModelagemcompleta = ({ setShowAlertMessage, setAlertMessage }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  // console.log('isModaNegocios360', isModaNegocios360)
  const logoTopo = isModaNegocios360 ? logoModaNegocios360 : logoTopoModelagemCompleta
  const titleContent = isModaNegocios360 ? (
    <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
  ) : (
    'Modelagem Completa'
  )

  // Definindo alturas com base na condição
  const logoTopoHeight = isModaNegocios360 ? '55px' : '60px' // Ajuste os valores conforme necessário
  const logoTopoWidth = isModaNegocios360 ? '65px' : '70px' // Ajuste os valores conforme necessário
  const titleHeight = isModaNegocios360 ? '60px' : undefined // Mantém a altura somente se for uma imagem

  const renderTitle = () => {
    if (React.isValidElement(titleContent)) {
      const style = titleHeight ? { height: titleHeight } : {}
      return React.cloneElement(titleContent, {
        style: { ...titleContent.props.style, ...style }
      })
    }
    return (
      <Banner
        title={titleContent}
        fontFamily="Josefin Sans"
        color="#102a43"
        width="100%"
        fontSize="30px"
        classEfeito3dMouseOver=""
      />
    )
  }

  return (
    <Navbar bg="light" expand="lg" className="index-top-header-homeMC">
      <div className="d-flex align-items-center">
        <Navbar.Brand href="" className="d-flex align-items-center">
          <div onClick={() => navigate('/home')}>
            <img
              src={logoTopo}
              alt="Logo"
              className="logo-topo-homePF"
              style={{ height: logoTopoHeight, width: logoTopoWidth }} // Aplica a altura e largura do logoTopo
            />
          </div>
        </Navbar.Brand>
        <div className="custom-title-image">{renderTitle()}</div>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto menu-items-container">
          <Nav.Link onClick={() => navigate('/funcionalidades')} id="index-link-nav-link">
            Funcionalidades
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/planos-e-precos-moda')} id="index-link-nav-link">
            Planos sob consulta
          </Nav.Link>
          {/* <Nav.Link href="#afiliado" id="index-link-nav-link">
            Seja um Afiliado
          </Nav.Link> */}
          <Nav.Link onClick={() => navigate('/fale-conosco')} id="index-link-nav-link">
            Fale conosco
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/cadastro-tenant')} className="experimente-gratis-btn">
            Experimente
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <CustomTooltip setShowAlertMessage={setShowAlertMessage} setAlertMessage={setAlertMessage}>
        <div className="entrar-link" onClick={() => navigate('/login')}>
          Entrar
        </div>
      </CustomTooltip>
    </Navbar>
  )
}
