//pdf-toolkit.jsx
import React, { useState } from 'react'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { Modal, Button, Spinner, Alert, Container, Row, Col, Form } from 'react-bootstrap'
import * as pdfjsLib from 'pdfjs-dist'
import { GlobalWorkerOptions } from 'pdfjs-dist'
const PDFToolkit = () => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [selectedOption, setSelectedOption] = useState('addPageNumbers')
  const [showEditor, setShowEditor] = useState(false)
  const [editableContent, setEditableContent] = useState('')
  const [originalContent, setOriginalContent] = useState('')
  const [showSejdaModal, setShowSejdaModal] = useState(false)

  GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs'
  const handleShowEditor = content => {
    setOriginalContent(content) // Armazena o conteúdo original
    setEditableContent(content) // Preenche o editor com o conteúdo
    setShowEditor(true)
  }

  //   const saveAsPDF = async (editableContent, originalPdfBytes) => {
  //     const originalPdfDoc = await PDFDocument.load(originalPdfBytes)
  //     const newPdfDoc = await PDFDocument.create()

  //     const pages = editableContent.split('<div class="page"').slice(1)

  //     for (let i = 0; i < pages.length; i++) {
  //       const originalPage = originalPdfDoc.getPages()[i]
  //       const [copiedPage] = await newPdfDoc.copyPages(originalPdfDoc, [i])
  //       newPdfDoc.addPage(copiedPage)

  //       const pageText = pages[i]
  //         .replace(/id="page-\d+">/g, '') // Remover IDs desnecessários
  //         .trim()

  //       if (pageText) {
  //         const { width, height } = copiedPage.getSize()
  //         copiedPage.drawText(pageText, {
  //           x: 50,
  //           y: height - 50, // Ajuste conforme necessário
  //           size: 12,
  //           color: rgb(0, 0, 0)
  //         })
  //       }
  //     }

  //     const pdfBytes = await newPdfDoc.save()
  //     const blob = new Blob([pdfBytes], { type: 'application/pdf' })
  //     const link = document.createElement('a')
  //     link.href = URL.createObjectURL(blob)
  //     link.download = 'edited_file.pdf'
  //     link.click()
  //   }
  const saveAsPDF = async (editableContent, originalPdfBytes) => {
    const originalPdfDoc = await PDFDocument.load(originalPdfBytes)
    const newPdfDoc = await PDFDocument.create()

    // Divide o conteúdo editado em páginas
    const pagesContent = editableContent.split('<div class="page">').slice(1)

    for (let i = 0; i < pagesContent.length; i++) {
      const pageContent = pagesContent[i].replace(/<\/?div[^>]*>/g, '').trim()
      const [copiedPage] = await newPdfDoc.copyPages(originalPdfDoc, [i])
      const newPage = newPdfDoc.addPage(copiedPage)

      if (pageContent) {
        const { width, height } = copiedPage.getSize()
        newPage.drawText(pageContent, {
          x: 50,
          y: height - 100,
          size: 12,
          color: rgb(0, 0, 0)
        })
      }
    }

    const pdfBytes = await newPdfDoc.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'edited_file.pdf'
    link.click()
  }

  //   const handleSaveChanges = async () => {
  //     if (!selectedFile) {
  //       setErrorMessage('Arquivo original não encontrado.')
  //       return
  //     }

  //     try {
  //       const fileReader = new FileReader()

  //       fileReader.onload = async e => {
  //         const originalPdfBytes = e.target.result

  //         // Reconvertendo o texto editado em PDF
  //         console.log('Conteúdo editado:', editableContent)
  //         await saveAsPDF(editableContent, originalPdfBytes)
  //         setShowEditor(false)
  //         setSuccessMessage('Alterações salvas com sucesso!')
  //       }

  //       fileReader.readAsArrayBuffer(selectedFile)
  //     } catch (error) {
  //       console.error('Erro ao salvar alterações:', error)
  //       setErrorMessage('Erro ao salvar alterações. Tente novamente.')
  //     }
  //   }
  function findContextInText(content, targetWord) {
    const index = content.indexOf(targetWord)
    if (index === -1) {
      return { prevWord: '', nextWord: '' }
    }
    return getPrevNextWords(content, index, targetWord)
  }

  function getPrevNextWords(text, index, word) {
    const beforePart = text.slice(0, index).trim()
    const afterPart = text.slice(index + word.length).trim()

    const beforeWords = beforePart.split(/\s+/)
    const afterWords = afterPart.split(/\s+/)

    const prevWord = beforeWords.length ? beforeWords[beforeWords.length - 1] : ''
    const nextWord = afterWords.length ? afterWords[0] : ''

    return { prevWord, nextWord }
  }

  const handleSaveChanges = async () => {
    if (!selectedFile) {
      setErrorMessage('Arquivo original não encontrado.')
      return
    }

    if (selectedFile.type !== 'application/pdf') {
      setErrorMessage('Por favor, selecione um arquivo PDF válido.')
      return
    }

    try {
      const fileReader = new FileReader()
      fileReader.onload = async e => {
        const originalPdfBytes = new Uint8Array(e.target.result)

        // Comparação de conteúdo após carregar o PDF
        const originalPages = originalContent.split('<div class="page">').slice(1)
        const editedPages = editableContent.split('<div class="page">').slice(1)
        const modifiedPagesIndices = []

        originalPages.forEach((origPage, index) => {
          const editedPage = editedPages[index] || ''
          if (origPage.trim() !== editedPage.trim()) {
            modifiedPagesIndices.push(index)
          }
        })

        // Inicializa variáveis para palavra alvo e contexto
        let targetWord = ''
        let prevContext = ''
        let nextContext = ''

        // Se houver páginas modificadas, determine a palavra alterada na primeira página modificada
        if (modifiedPagesIndices.length > 0) {
          const firstModifiedIndex = modifiedPagesIndices[0]
          const origWords = originalPages[firstModifiedIndex].trim().split(/\s+/)
          const editedWords = editedPages[firstModifiedIndex].trim().split(/\s+/)

          // Encontrar a primeira palavra que difere entre o original e o editado
          for (let i = 0; i < Math.min(origWords.length, editedWords.length); i++) {
            if (origWords[i] !== editedWords[i]) {
              targetWord = origWords[i] // Palavra original que foi alterada
              prevContext = i > 0 ? origWords[i - 1] : ''
              nextContext = i < origWords.length - 1 ? origWords[i + 1] : ''
              break
            }
          }
        }

        // Chama a função replaceTextInPDF usando os índices modificados e contexto extraído
        await replaceTextInPDF(
          originalPdfBytes,
          originalContent, // Passa o conteúdo original aqui
          editableContent,
          modifiedPagesIndices,
          targetWord,
          prevContext,
          nextContext
        )

        setShowEditor(false)
        setSuccessMessage('Alterações salvas com sucesso!')
      }

      fileReader.onerror = () => {
        setErrorMessage('Erro ao carregar o arquivo PDF.')
      }

      fileReader.readAsArrayBuffer(selectedFile)
    } catch (error) {
      console.error('Erro ao salvar alterações:', error)
      setErrorMessage('Erro ao salvar alterações. Tente novamente.')
    }
  }

  const handleImageUpload = event => {
    setSelectedImage(event.target.files[0])
    setErrorMessage('')
    setSuccessMessage('')
  }

  const handleOptionChange = event => {
    setSelectedOption(event.target.value)
  }

  const addPageNumbers = async pdfDoc => {
    const pages = pdfDoc.getPages()
    const totalPages = pages.length
    pages.forEach((page, index) => {
      if (index > 0) {
        const { width, height } = page.getSize()
        page.drawText(`Página ${index} de ${totalPages - 1}`, {
          x: width / 2 - 50,
          y: 20,
          size: 10,
          color: rgb(0, 0, 0)
        })
      }
    })
  }

  const convertWebpToPng = async webpFile => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = event => {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          canvas.toBlob(blob => {
            resolve(blob)
          }, 'image/png')
        }
        img.onerror = reject
        img.src = event.target.result
      }
      reader.onerror = reject
      reader.readAsDataURL(webpFile)
    })
  }

  //   const addCoverPage = async pdfDoc => {
  //     if (!selectedImage) {
  //       throw new Error('Nenhuma imagem de capa selecionada.')
  //     }

  //     const imageBytes = await selectedImage.arrayBuffer()
  //     const imageFormat = selectedImage.type

  //     let coverImage
  //     if (imageFormat === 'image/jpeg' || imageFormat === 'image/jpg') {
  //       coverImage = await pdfDoc.embedJpg(imageBytes)
  //     } else if (imageFormat === 'image/png') {
  //       coverImage = await pdfDoc.embedPng(imageBytes)
  //     } else if (imageFormat === 'image/webp') {
  //       const pngBlob = await convertWebpToPng(selectedImage)
  //       const pngArrayBuffer = await pngBlob.arrayBuffer()
  //       coverImage = await pdfDoc.embedPng(pngArrayBuffer)
  //     } else {
  //       throw new Error('Formato de imagem não suportado. Use JPG, JPEG, PNG ou WEBP.')
  //     }

  //     const coverPage = pdfDoc.addPage([coverImage.width, coverImage.height])
  //     coverPage.drawImage(coverImage, {
  //       x: 0,
  //       y: 0,
  //       width: coverImage.width,
  //       height: coverImage.height
  //     })
  //   }
  const addCoverPage = async pdfDoc => {
    if (!selectedImage) {
      throw new Error('Nenhuma imagem de capa selecionada.')
    }

    const imageBytes = await selectedImage.arrayBuffer()
    const imageFormat = selectedImage.type

    let coverImage
    if (imageFormat === 'image/jpeg' || imageFormat === 'image/jpg') {
      coverImage = await pdfDoc.embedJpg(imageBytes)
    } else if (imageFormat === 'image/png') {
      coverImage = await pdfDoc.embedPng(imageBytes)
    } else if (imageFormat === 'image/webp') {
      const pngBlob = await convertWebpToPng(selectedImage)
      const pngArrayBuffer = await pngBlob.arrayBuffer()
      coverImage = await pdfDoc.embedPng(pngArrayBuffer)
    } else {
      throw new Error('Formato de imagem não suportado. Use JPG, JPEG, PNG ou WEBP.')
    }

    const { width, height } = coverImage
    const coverPage = pdfDoc.insertPage(0, [width, height])
    coverPage.drawImage(coverImage, {
      x: 0,
      y: 0,
      width,
      height
    })
  }

  const editText = async (pdfDoc, oldText, newText) => {
    const pages = pdfDoc.getPages()
    for (const page of pages) {
      const { width, height } = page.getSize()

      // Este exemplo desenha o texto substituído em uma nova camada
      page.drawText(newText, {
        x: 100, // Ajustar posição
        y: height - 100, // Ajustar posição
        size: 12,
        color: rgb(1, 0, 0) // Cor vermelha para indicar alteração
      })
    }
  }

  //   const handleProcessFile = async () => {
  //     if (!selectedFile) {
  //       setErrorMessage('Por favor, carregue um arquivo PDF antes de processar.')
  //       return
  //     }

  //     try {
  //       setLoading(true)

  //       const fileReader = new FileReader()

  //       fileReader.onload = async e => {
  //         const existingPdfBytes = e.target.result

  //         // Carregar o PDF existente
  //         const pdfDoc = await PDFDocument.load(existingPdfBytes)
  //         if (selectedOption === 'editText') {
  //           const oldText = prompt('Digite o texto a ser substituído:')
  //           const newText = prompt('Digite o novo texto:')
  //           await editText(pdfDoc, oldText, newText)
  //         }
  //         if (selectedOption === 'addPageNumbers') {
  //           await addPageNumbers(pdfDoc)
  //         } else if (selectedOption === 'addCoverPage') {
  //           await addCoverPage(pdfDoc)
  //         }

  //         // Salvar o PDF modificado
  //         const modifiedPdfBytes = await pdfDoc.save()
  //         const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' })

  //         // Criar link para download do PDF modificado
  //         const link = document.createElement('a')
  //         link.href = URL.createObjectURL(blob)
  //         link.download = 'arquivo_processado.pdf'
  //         document.body.appendChild(link)
  //         link.click()
  //         document.body.removeChild(link)

  //         setSuccessMessage('PDF processado com sucesso! O download foi iniciado.')
  //       }

  //       fileReader.readAsArrayBuffer(selectedFile)
  //     } catch (error) {
  //       console.error('Erro ao processar o arquivo:', error)
  //       setErrorMessage(error.message || 'Ocorreu um erro ao processar o arquivo. Tente novamente.')
  //     } finally {
  //       setLoading(false)
  //     }
  //   }
  const handleProcessFile = async () => {
    if (!selectedFile) {
      setErrorMessage('Por favor, carregue um arquivo PDF antes de processar.')
      return
    }

    try {
      setLoading(true)

      const fileReader = new FileReader()

      fileReader.onload = async e => {
        const existingPdfBytes = e.target.result

        // Carregar o PDF existente

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        if (selectedOption === 'editText') {
          const extractedContent = await extractPDFContent(existingPdfBytes) // Extração do texto
          handleShowEditor(extractedContent) // Abre o modal com conteúdo extraído
        } else if (selectedOption === 'addPageNumbers') {
          await addPageNumbers(pdfDoc)
        } else if (selectedOption === 'addCoverPage') {
          await addCoverPage(pdfDoc)
        }

        if (selectedOption !== 'editText') {
          // Salvar o PDF modificado
          const modifiedPdfBytes = await pdfDoc.save()
          const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' })

          // Criar link para download do PDF modificado
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'arquivo_processado.pdf'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          setSuccessMessage('PDF processado com sucesso! O download foi iniciado.')
        }
      }

      fileReader.readAsArrayBuffer(selectedFile)
    } catch (error) {
      console.error('Erro ao processar o arquivo:', error)
      setErrorMessage(error.message || 'Ocorreu um erro ao processar o arquivo. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  const extractPDFContent = async pdfBytes => {
    const loadingTask = pdfjsLib.getDocument({ data: pdfBytes })
    const pdf = await loadingTask.promise
    let extractedContent = ''

    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const page = await pdf.getPage(pageNumber)
      const textContent = await page.getTextContent()

      const pageText = textContent.items.map(item => item.str).join(' ')

      extractedContent += `<div class="page">${pageText}</div>` // Removido id="page-x"
    }

    return extractedContent
  }

  //   const extractTextWithPositions = async pdfBytes => {
  //     const loadingTask = pdfjsLib.getDocument({ data: pdfBytes })
  //     const pdf = await loadingTask.promise

  //     const pages = []
  //     for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //       const page = await pdf.getPage(pageNum)
  //       const textContent = await page.getTextContent()
  //       console.log(`texto da pagina ${pageNum}`, textContent)

  //       const viewport = page.getViewport({ scale: 1 })

  //       const pageTexts = textContent.items.map(item => ({
  //         str: item.str, // Texto
  //         x: item.transform[4], // Posição X
  //         y: viewport.height - item.transform[5] // Posição Y ajustada
  //       }))

  //       pages.push(pageTexts)
  //     }

  //     return pages // Retorna uma lista com texto e posições por página
  //   }
  const extractTextWithPositions = async pdfBytes => {
    const loadingTask = pdfjsLib.getDocument({ data: pdfBytes })
    const pdf = await loadingTask.promise

    const pages = []
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum)
      const textContent = await page.getTextContent()
      const viewport = page.getViewport({ scale: 1 })

      const pageTexts = []
      for (const item of textContent.items) {
        const words = item.str.split(/\s+/) // Divide o texto em palavras
        let currentX = item.transform[4]
        let spaceWidth = item.width / (item.str.length || 1) // Largura média por caractere

        for (const word of words) {
          if (word.trim()) {
            // Ignorar strings vazias
            pageTexts.push({
              str: word,
              x: currentX,
              y: viewport.height - item.transform[5] // Ajusta a posição Y
            })
          }
          currentX += word.length * spaceWidth // Avança a posição X
        }
      }

      pages.push(pageTexts)
    }
    console.log('pages', pages)
    return pages // Retorna uma lista com texto e posições por página
  }

  const validatePDFHeader = async file => {
    const fileReader = new FileReader()
    return new Promise((resolve, reject) => {
      fileReader.onload = e => {
        const header = new TextDecoder().decode(new Uint8Array(e.target.result).slice(0, 5))
        if (header.startsWith('%PDF')) {
          resolve(true) // É um PDF válido
        } else {
          reject(new Error('O arquivo selecionado não é um PDF válido.'))
        }
      }
      fileReader.onerror = () => reject(new Error('Erro ao carregar o arquivo.'))
      fileReader.readAsArrayBuffer(file)
    })
  }

  const handleFileUpload = async event => {
    const file = event.target.files[0]
    try {
      const isValidPDF = await validatePDFHeader(file)
      if (isValidPDF) {
        setSelectedFile(file)
        setErrorMessage('')
        setSuccessMessage('')
      }
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const replaceTextInPDF = async (
    originalPdfBytes,
    originalContent, // Novo parâmetro: conteúdo original
    editableContent,
    pagesToModify,
    targetWord,
    prevContext,
    nextContext
  ) => {
    console.log('Iniciando replaceTextInPDF')
    console.log({ pagesToModify, targetWord, prevContext, nextContext })
    const pdfDoc = await PDFDocument.load(originalPdfBytes)
    const textPositions = await extractTextWithPositions(originalPdfBytes)
    console.log('textPositions', textPositions)
    const pages = pdfDoc.getPages()

    // Dividir o conteúdo original em páginas para uso no contexto
    const originalPagesArray = originalContent.split('<div class="page">').slice(1)

    editableContent
      .split('<div class="page">')
      .slice(1)
      .forEach((pageContent, index) => {
        if (!pagesToModify.includes(index)) return
        console.log(`Processando página ${index + 1}`)

        const page = pages[index]

        if (!textPositions[index] || textPositions[index].length === 0) {
          console.log(`Nenhum texto encontrado na página ${index + 1}`)
          return
        }

        // Usar o texto original da página para obter o contexto
        const originalPageText = originalPagesArray[index].trim()
        let searchIndex = 0

        while ((searchIndex = originalPageText.indexOf(targetWord, searchIndex)) !== -1) {
          const { prevWord, nextWord } = getPrevNextWords(originalPageText, searchIndex, targetWord)

          console.log('palavra em questao', targetWord)
          console.log('prevPDF', prevWord)
          console.log('nextPDF', nextWord)

          console.log('prevModal', prevContext)
          console.log('nextModal', nextContext)

          if (prevWord === prevContext && nextWord === nextContext) {
            for (let i = 0; i < textPositions[index].length; i++) {
              // console.log(`Verificando texto na posição ${i}`)

              const text = textPositions[index][i]
              console.log('palavra no pdf', text?.str)
              const palavraPDFAntes = textPositions[index][i - 1]?.str
              const palavraPDFDepois = textPositions[index][i + 1]?.str
              //  console.log('targetWord', targetWord)
              if (
                text &&
                text.str.includes(targetWord) &&
                palavraPDFAntes === prevContext &&
                palavraPDFDepois === nextContext
              ) {
                const nextText = textPositions[index][i + 1]
                const estimatedWidth = nextText ? nextText.x - text.x : text.str.length * 6
                console.log(
                  `Desenhando tarja vermelha em (${text.x - 2}, ${
                    text.y - 10
                  }) com largura ${estimatedWidth}`
                )
                page.drawRectangle({
                  x: text.x + 0,
                  y: text.y + 563,
                  width: 50,
                  height: 12,
                  color: rgb(1, 0, 0)
                })
              }
            }

            console.log(`Desenhando novo texto sobre a tarja na página ${index + 1}`)
            page.drawText(editableContent, {
              x: textPositions[index][0].x,
              y: textPositions[index][0].y,
              size: 12,
              color: rgb(0, 0, 0)
            })

            break
          }
          searchIndex += targetWord.length
        }
      })

    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'edited_file.pdf'
    link.click()
  }

  return (
    <Container>
      <Row className="mt-4">
        <Col md={8} className="mx-auto">
          <div className="text-center mb-4">
            <h4>Ferramenta de Manipulação de PDFs</h4>
          </div>

          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <Form>
            <Form.Check
              type="radio"
              label="Adicionar Números de Página"
              value="addPageNumbers"
              checked={selectedOption === 'addPageNumbers'}
              onChange={handleOptionChange}
            />
            <Form.Check
              type="radio"
              label="Adicionar Capa"
              value="addCoverPage"
              checked={selectedOption === 'addCoverPage'}
              onChange={handleOptionChange}
            />
            <Form.Check
              type="radio"
              label="Editar Texto"
              value="editText"
              checked={selectedOption === 'editText'}
              onChange={event => {
                setSelectedOption(event.target.value)
                setShowSejdaModal(true) // Mostra o modal
              }}
              // onChange={() => {
              //   setSelectedOption('editText')
              //   window.open('https://www.sejda.com/pdf-editor', '_blank')
              // }}
            />
          </Form>

          {selectedOption === 'addCoverPage' && (
            <div className="mt-3">
              <Form.Label>Carregar Imagem de Capa</Form.Label>
              <input
                type="file"
                accept="image/jpeg, image/jpg, image/png, image/webp"
                onChange={handleImageUpload}
                className="form-control"
                style={{ maxWidth: '300px' }}
              />
            </div>
          )}

          <div className="d-flex justify-content-center align-items-center mt-3">
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileUpload}
              className="form-control"
              style={{ maxWidth: '300px' }}
            />

            <Button
              variant="primary"
              onClick={handleProcessFile}
              style={{ marginLeft: '10px' }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> Processando...
                </>
              ) : (
                'Processar Arquivo'
              )}
            </Button>
          </div>
        </Col>
      </Row>
      <Modal show={showEditor} onHide={() => setShowEditor(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Editar Texto do PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            contentEditable
            style={{
              border: '1px solid #ccc',
              padding: '10px',
              height: '400px',
              overflowY: 'scroll'
            }}
            dangerouslySetInnerHTML={{ __html: editableContent }}
            onInput={e => setEditableContent(e.currentTarget.innerHTML)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditor(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Salvar Alterações
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSejdaModal} onHide={() => setShowSejdaModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Editor de Texto Sejda</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '500px' }}>
          <iframe
            src="http://localhost:3000/proxy/sejda" // URL do proxy reverso para teste local
            title="Editor de PDF Sejda"
            style={{ width: '100%', height: '100%', border: 'none' }}
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSejdaModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default PDFToolkit
