import { useEffect, useState } from 'react'

import Select from 'react-select'
import { Container, Table, Spinner, Button } from 'react-bootstrap'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable' // Importar o plugin necessário para tabelas, caso esteja usando `autoTable`

import DatePicker from 'react-datepicker'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner.jsx'
import { fetchData } from '../../../../../../src/backend/model.js'
import { useContext } from 'react'
import { AuthContext } from '../../../../../../src/context/AuthContext.jsx'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { PDFDocument, rgb } from 'pdf-lib'
const RelatorioFrequencia = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [dados, setDados] = useState([])
  const [loading, setLoading] = useState(true)
  const [mostrarSubmenu, setMostrarSubmenu] = useState(false)
  const [mostrarSetor, setMostrarSetor] = useState(false)
  const [mostrarCargo, setMostrarCargo] = useState(false)
  //const [filtrarSetor, setFiltrarSetor] = useState(false)
  //const [filtrarCargo, setFiltrarCargo] = useState(false)
  const [dataIntervalo, setDataIntervalo] = useState([null, null])
  const [colaboradorSelecionado, setColaboradorSelecionado] = useState('')
  const [todosFuncionarios, setTodosFuncionarios] = useState([])
  const [totalPresentes, setTotalPresentes] = useState(0)
  const [totalHorasTrabalhadas, setTotalHorasTrabalhadas] = useState('--:--')
  const [filtroSelecionado, setFiltroSelecionado] = useState('') // "setor" ou "cargo"
  const [setorSelecionado, setSetorSelecionado] = useState('')
  const [cargoSelecionado, setCargoSelecionado] = useState('')
  const [setores, setSetores] = useState([]) // Lista de setores
  const [cargos, setCargos] = useState([]) // Lista de cargos

  useEffect(() => {
    const fetchRelatorioFrequencia = async () => {
      try {
        const url = `${config.host}/relogioPontoRelatorios`

        const response = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'gerarRelatorioFrequencia',
          dataInicio: dataIntervalo[0]?.toISOString() || null,
          dataFim: dataIntervalo[1]?.toISOString() || null
        })

        console.log('Dados recebidos do backend:', response)
        setDados(response || [])
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar relatório de frequência:', error)
        setLoading(false)
      }
    }

    fetchRelatorioFrequencia()
  }, [RotaBase, tenantId, dataIntervalo])

  useEffect(() => {
    const fetchTodosFuncionarios = async () => {
      try {
        const url = `${config.host}/funcionariosAtivos`
        const response = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionarios'
        })
        console.log('Funcionários recebidos:', response)
        setTodosFuncionarios(response || [])
      } catch (error) {
        console.error('Erro ao buscar colaboradores:', error)
      }
    }

    fetchTodosFuncionarios()
  }, [RotaBase, tenantId])

  useEffect(() => {
    if (dados.length > 0) {
      // Calcular total de presentes
      setTotalPresentes(dados.length)

      // Calcular total de horas trabalhadas
      const totalHoras = dados.reduce((acc, colaborador) => {
        if (colaborador.horasTrabalhadas !== '--:--') {
          const [horas, minutos] = colaborador.horasTrabalhadas.split('h').map(s => parseInt(s))
          return acc + horas * 60 + minutos // Converte tudo para minutos
        }
        return acc
      }, 0)

      // Converter minutos para horas e minutos novamente
      const horasTrabalhadas = Math.floor(totalHoras / 60)
      const minutosTrabalhados = totalHoras % 60
      setTotalHorasTrabalhadas(`${horasTrabalhadas}h ${minutosTrabalhados}m`)
    } else {
      setTotalPresentes(0)
      setTotalHorasTrabalhadas('--:--')
    }
  }, [dados])

  useEffect(() => {
    const fetchSetoresECargos = async () => {
      try {
        const urlSetores = `${config.host}/gerenciarSetores`
        const setoresResponse = await fetchData(urlSetores, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarTodosOsSetores'
        })
        setSetores(setoresResponse.setores || [])

        const urlCargos = `${config.host}/gerenciarCargos`
        const cargosResponse = await fetchData(urlCargos, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarTodosOsCargos'
        })
        setCargos(cargosResponse.cargos || [])
      } catch (error) {
        console.error('Erro ao buscar setores e cargos:', error)
      }
    }

    fetchSetoresECargos()
  }, [RotaBase, tenantId])

  // Exportar para Excel
  const exportarParaExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dados)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório')
    XLSX.writeFile(workbook, 'relatorio_frequencia.xlsx')
  }

  // Exportar para CSV
  const exportarParaCsv = () => {
    const csv = dados.map(row => Object.values(row).join(',')).join('\n')
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'relatorio_frequencia.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // Exportar para PDF
  const exportarParaPdf = () => {
    const doc = new jsPDF()
    doc.text('Relatório de Frequência', 10, 10)
    const columns = [
      'Nome do Colaborador',
      'Setor',
      'Cargo',
      'Data',
      'Hora de Entrada',
      'Hora de Saída',
      'Horas Trabalhadas'
    ]
    const rows = dados.map(
      ({ nome, setor, cargo, data, horaEntrada, horaSaida, horasTrabalhadas }) => [
        nome,
        setor || '--',
        cargo || '--',
        data,
        horaEntrada,
        horaSaida,
        horasTrabalhadas
      ]
    )
    doc.autoTable({ head: [columns], body: rows })
    doc.save('relatorio_frequencia.pdf')
  }

  return (
    <Container>
      {loading && <Spinner animation="border" />}
      <div className="text-center my-4">
        <Banner title="Relógio de Ponto" width="auto" fontSize={57} classEfeito3dMouseOver="" />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <h4 className="text-center mb-4" style={{ marginBottom: '0' }}>
          Relatório de Frequência
        </h4>

        {/* Resumo ao lado */}
        <div
          style={{
            padding: '10px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            border: '1px solid #ddd',
            maxWidth: '50%',
            textAlign: 'left',
            marginLeft: '10px'
          }}
        >
          <h6 style={{ marginBottom: '10px', color: '#007bff' }}>Resumo</h6>
          <ul style={{ listStyle: 'none', paddingLeft: '0', margin: '0' }}>
            <li>
              <strong>Total de Presentes:</strong> {totalPresentes || 0}
            </li>
            <li>
              <strong>Total Horas Trabalhadas:</strong> {totalHorasTrabalhadas || '--:--'}
            </li>
          </ul>
        </div>
      </div>

      {/* Div de mais opções */}
      <div
        className="opcoes-container"
        onMouseEnter={() => setMostrarSubmenu(true)}
        onMouseLeave={() => setMostrarSubmenu(false)}
        style={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: '#f9f9f9',
          transition: 'box-shadow 0.3s',
          position: 'relative',
          width: '250px',
          alignSelf: 'flex-start'
        }}
      >
        Mais opções
        {mostrarSubmenu && (
          <div
            className="submenu"
            style={{
              display: 'block',
              position: 'absolute',
              top: '100%',
              left: '0',
              width: '250px',
              backgroundColor: '#fff',
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              zIndex: 10,
              marginTop: '1px'
            }}
          >
            <div
              style={{
                maxHeight: '360px', // Altura máxima para o contêiner
                maxWidth: '250px', // Largura máxima para o contêiner
                overflowY: 'auto', // Habilita a rolagem vertical
                border: '1px solid #ddd',
                borderRadius: '8px',
                padding: '10px',
                backgroundColor: '#fff' // Fundo claro para destaque
              }}
            >
              {/* Div para exibir colunas */}
              <div style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <h6 style={{ color: '#007bff' }}>Exibir Colunas</h6>
                <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <input
                    type="checkbox"
                    checked={mostrarSetor}
                    onChange={e => setMostrarSetor(e.target.checked)}
                  />
                  <span>Mostrar Setor</span>
                </label>
                <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <input
                    type="checkbox"
                    checked={mostrarCargo}
                    onChange={e => setMostrarCargo(e.target.checked)}
                  />
                  <span>Mostrar Cargo</span>
                </label>
              </div>

              {/* Div para aplicar filtros */}
              <div style={{ padding: '10px', borderTop: '1px solid #ddd', marginTop: '10px' }}>
                <h6 style={{ color: '#28a745' }}>Aplicar Filtros</h6>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  {/* Radio Buttons para Selecionar o Tipo de Filtro */}
                  <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <input
                      type="radio"
                      name="filtro"
                      value="setor"
                      checked={filtroSelecionado === 'setor'}
                      onChange={e => setFiltroSelecionado(e.target.value)}
                    />
                    <span>Mostrar por Setor</span>
                  </label>
                  <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <input
                      type="radio"
                      name="filtro"
                      value="cargo"
                      checked={filtroSelecionado === 'cargo'}
                      onChange={e => setFiltroSelecionado(e.target.value)}
                    />
                    <span>Mostrar por Cargo</span>
                  </label>
                  <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <input
                      type="radio"
                      name="filtro"
                      value=""
                      checked={filtroSelecionado === ''}
                      onChange={() => {
                        setFiltroSelecionado('')
                        setSetorSelecionado('')
                        setCargoSelecionado('')
                      }}
                    />
                    <span>Exibir Todos</span>
                  </label>
                  {/* Select para Setor */}
                  {filtroSelecionado === 'setor' && (
                    <select
                      value={setorSelecionado}
                      onChange={e => {
                        const setor = setores.find(s => s._id === e.target.value) // Busca pelo ID
                        setSetorSelecionado(setor ? setor.setor : '') // Define o nome do setor
                      }}
                      style={{
                        padding: '10px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        backgroundColor: '#f9f9f9'
                      }}
                    >
                      <option value="">Selecione um setor</option>
                      {setores.map(setor => (
                        <option key={setor._id} value={setor._id}>
                          {setor.setor}
                        </option>
                      ))}
                    </select>
                  )}

                  {/* Select para Cargo */}
                  {filtroSelecionado === 'cargo' && (
                    <select
                      value={cargoSelecionado}
                      onChange={e => {
                        const cargo = cargos.find(c => c._id === e.target.value) // Busca pelo ID
                        setCargoSelecionado(cargo ? cargo.nome : '') // Define o nome do cargo
                      }}
                      style={{
                        padding: '10px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        backgroundColor: '#f9f9f9'
                      }}
                    >
                      <option value="">Selecione um cargo</option>
                      {cargos.map(cargo => (
                        <option key={cargo._id} value={cargo._id}>
                          {cargo.nome}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>

              {/* Div para intervalo de datas */}
              <div style={{ padding: '10px', borderTop: '1px solid #ddd', marginTop: '10px' }}>
                <h6 style={{ color: '#ff9800' }}>Filtrar por Data</h6>
                <DatePicker
                  selected={dataIntervalo[0]}
                  onChange={dates => setDataIntervalo(dates)}
                  startDate={dataIntervalo[0]}
                  endDate={dataIntervalo[1]}
                  selectsRange
                  isClearable
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Selecione o intervalo de datas"
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '8px',
                    border: '1px solid #ddd',
                    backgroundColor: '#f9f9f9'
                  }}
                />
              </div>

              {/* Div para selecionar colaborador */}
              <div style={{ padding: '10px', borderTop: '1px solid #ddd', marginTop: '10px' }}>
                <h6 style={{ color: '#17a2b8' }}>Selecionar Colaborador</h6>
                <Select
                  options={todosFuncionarios.map(funcionario => ({
                    value: funcionario.Nome,
                    label: funcionario.Nome
                  }))}
                  placeholder="Pesquise um colaborador..."
                  isClearable
                  value={
                    colaboradorSelecionado
                      ? { value: colaboradorSelecionado, label: colaboradorSelecionado }
                      : null
                  }
                  onChange={selectedOption => {
                    console.log('Colaborador selecionado:', selectedOption) // Log do valor selecionado
                    setColaboradorSelecionado(selectedOption ? selectedOption.value : '')
                  }}
                  styles={{
                    control: provided => ({
                      ...provided,
                      padding: '5px',
                      borderRadius: '8px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f9f9f9'
                    })
                  }}
                />
              </div>

              {/* Div para exportação de dados */}
              <div style={{ padding: '10px', borderTop: '1px solid #ddd', marginTop: '10px' }}>
                <h6 style={{ color: '#dc3545' }}>Exportar Dados</h6>
                <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                  <button
                    onClick={() => exportarParaExcel()}
                    style={{
                      padding: '10px',
                      backgroundColor: '#007bff',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    Exportar para Excel
                  </button>
                  <button
                    onClick={() => exportarParaCsv()}
                    style={{
                      padding: '10px',
                      backgroundColor: '#28a745',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    Exportar para CSV
                  </button>
                  <button
                    onClick={() => exportarParaPdf()}
                    style={{
                      padding: '10px',
                      backgroundColor: '#dc3545',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer'
                    }}
                  >
                    Exportar para PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Indicador de Filtro Ativo */}
      {(filtroSelecionado || (dataIntervalo[0] && dataIntervalo[1]) || colaboradorSelecionado) && (
        <div
          style={{
            margin: '10px 0',
            padding: '10px',
            backgroundColor: colaboradorSelecionado
              ? '#e8f5e9'
              : filtroSelecionado === 'setor'
              ? '#d1ecf1'
              : filtroSelecionado === 'cargo'
              ? '#f8d7da'
              : '#e2e3e5',
            color: colaboradorSelecionado
              ? '#2e7d32'
              : filtroSelecionado === 'setor'
              ? '#0c5460'
              : filtroSelecionado === 'cargo'
              ? '#721c24'
              : '#383d41',
            borderRadius: '5px',
            textAlign: 'center',
            fontWeight: 'bold',
            border: `1px solid ${
              colaboradorSelecionado
                ? '#c8e6c9'
                : filtroSelecionado === 'setor'
                ? '#bee5eb'
                : filtroSelecionado === 'cargo'
                ? '#f5c6cb'
                : '#d6d8db'
            }`
          }}
        >
          {colaboradorSelecionado ? (
            <>
              Listando registros para o colaborador{' '}
              <span style={{ fontWeight: 'bold' }}>{colaboradorSelecionado}</span>
            </>
          ) : filtroSelecionado === 'cargo' &&
            cargoSelecionado &&
            dataIntervalo[0] &&
            dataIntervalo[1] ? (
            <>
              Listando registros para o cargo{' '}
              <span style={{ fontWeight: 'bold' }}>{cargoSelecionado}</span> entre as datas:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {dataIntervalo[0].toLocaleDateString('pt-BR')} e{' '}
                {dataIntervalo[1].toLocaleDateString('pt-BR')}
              </span>
            </>
          ) : filtroSelecionado === 'setor' &&
            setorSelecionado &&
            dataIntervalo[0] &&
            dataIntervalo[1] ? (
            <>
              Listando registros para o setor{' '}
              <span style={{ fontWeight: 'bold' }}>{setorSelecionado}</span> entre as datas:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {dataIntervalo[0].toLocaleDateString('pt-BR')} e{' '}
                {dataIntervalo[1].toLocaleDateString('pt-BR')}
              </span>
            </>
          ) : filtroSelecionado === 'cargo' && cargoSelecionado ? (
            <>
              Listando registros para o cargo{' '}
              <span style={{ fontWeight: 'bold' }}>{cargoSelecionado}</span>
            </>
          ) : filtroSelecionado === 'setor' && setorSelecionado ? (
            <>
              Listando registros para o setor{' '}
              <span style={{ fontWeight: 'bold' }}>{setorSelecionado}</span>
            </>
          ) : dataIntervalo[0] && dataIntervalo[1] ? (
            <>
              Listando registros entre as datas:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {dataIntervalo[0].toLocaleDateString('pt-BR')} e{' '}
                {dataIntervalo[1].toLocaleDateString('pt-BR')}
              </span>
            </>
          ) : (
            <>Nenhum filtro aplicado ainda.</>
          )}
        </div>
      )}

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nome do Colaborador</th>
              {mostrarSetor && <th>Setor</th>}
              {mostrarCargo && <th>Cargo</th>}
              <th>Data</th>
              <th>Hora de Entrada</th>
              <th>Hora de Saída</th>
              <th>Horas Trabalhadas</th>
            </tr>
          </thead>

          <tbody>
            {dados
              ?.filter(colaborador => {
                if (colaboradorSelecionado) {
                  const match = colaborador.nome === colaboradorSelecionado // Compare com 'name'

                  return match
                }
                if (filtroSelecionado === 'setor' && setorSelecionado) {
                  return colaborador.setor === setorSelecionado
                }
                if (filtroSelecionado === 'cargo' && cargoSelecionado) {
                  return colaborador.cargo === cargoSelecionado
                }
                return true
              })
              .map((colaborador, index) => (
                <tr key={index}>
                  <td>{colaborador.nome}</td> {/* Ajuste para exibir 'name' */}
                  {mostrarSetor && <td>{colaborador.setor || '--'}</td>}
                  {mostrarCargo && <td>{colaborador.cargo || '--'}</td>}
                  <td>{colaborador.data.split('-').reverse().join('-')}</td>
                  <td>{colaborador.horaEntrada}</td>
                  <td>{colaborador.horaSaida}</td>
                  <td>{colaborador.horasTrabalhadas}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}

export default RelatorioFrequencia
