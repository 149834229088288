import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../backend/configConexaoGeral.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { Container, Spinner, Form, Button, Table } from 'react-bootstrap'
import Banner from '../../CadastroGeral/Banner.jsx'

const GerenciamentoCargos = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [cargos, setCargos] = useState([])
  const [funcionarios, setFuncionarios] = useState([])
  const [nomeCargo, setNomeCargo] = useState('')
  const [descricaoCargo, setDescricaoCargo] = useState('')
  const [salarioBase, setSalarioBase] = useState('')
  //const [valorHora, setValorHora] = useState('')
  const [valorDiaria, setValorDiaria] = useState('')
  const [valorHoraExtra, setValorHoraExtra] = useState('')
  const [cargoSelecionado, setCargoSelecionado] = useState(null)
  const [funcionarioId, setFuncionarioId] = useState('')
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'
  const [setores, setSetores] = useState([])
  const [setorSelecionado, setSetorSelecionado] = useState('')
  const [cargosDoSetor, setCargosDoSetor] = useState([])
  const [funcionariosDoSetor, setFuncionariosDoSetor] = useState([])
  const [funcionariosDoSetorECargo, setFuncionariosDoSetorECargo] = useState([])
  const [modoEdicao, setModoEdicao] = useState(false)

  // Carregar setores no useEffect
  useEffect(() => {
    const fetchSetores = async () => {
      try {
        const url = `${config.host}/gerenciarSetores`
        const setoresResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarTodosOsSetores'
        })
        console.log('setoresResponse:', setoresResponse)
        setSetores(setoresResponse.setores || [])
      } catch (error) {
        console.error('Erro ao buscar setores:', error)
      }
    }

    fetchSetores()
  }, [RotaBase, tenantId])

  // Carregar cargos e funcionários ao selecionar setor
  useEffect(() => {
    if (setorSelecionado) {
      const fetchCargosEFuncionarios = async () => {
        try {
          const urlCargos = `${config.host}/gerenciarCargos`
          const cargosResponse = await fetchData(urlCargos, {
            tenantId,
            rota_base: RotaBase,
            setorId: setorSelecionado,
            requisicao: 'listarCargosDoSetor'
          })
          console.log('cargosResponse:', cargosResponse)
          console.log('cargosResponse.cargos:', cargosResponse.cargos)
          setCargosDoSetor(cargosResponse.cargos || [])
          const urlFuncionarios = `${config.host}/gerenciarCargos` // Alterado para chamar na rota de cargos

          const funcionariosDoSetorResponse = await fetchData(urlFuncionarios, {
            tenantId,
            rota_base: RotaBase,
            setorId: setorSelecionado,
            requisicao: 'listarFuncionariosDoSetor'
          })
          console.log('funcionariosDoSetorResponse:', funcionariosDoSetorResponse)

          setFuncionariosDoSetor(funcionariosDoSetorResponse.funcionarios || [])
        } catch (error) {
          console.error('Erro ao buscar cargos e funcionários:', error)
        }
      }

      fetchCargosEFuncionarios()
    }
  }, [setorSelecionado])

  //crie um useEffect para carregar os funcionários do setor e do cargo selecionado
  useEffect(() => {
    if (setorSelecionado && cargoSelecionado) {
      const fetchFuncionariosDoSetorECargo = async () => {
        try {
          const url = `${config.host}/gerenciarCargos`
          const funcionariosDoSetorECargoResponse = await fetchData(url, {
            tenantId,
            rota_base: RotaBase,
            setorId: setorSelecionado,
            cargoId: cargoSelecionado,
            requisicao: 'listarFuncionariosDoSetorECargo'
          })

          setFuncionariosDoSetorECargo(funcionariosDoSetorECargoResponse.funcionarios || [])
        } catch (error) {
          console.error('Erro ao buscar funcionários do setor e cargo:', error)
        }
      }

      fetchFuncionariosDoSetorECargo()
    }
  }, [setorSelecionado, cargoSelecionado])

  // Manter o comportamento de carregar cargos e funcionários
  const fetchCargos = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarCargos`
      const cargosResponse = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'listarTodosOsCargos'
      })
      console.log('Cargos recarregados:', cargosResponse.cargos)
      setCargos(cargosResponse.cargos || [])
      setLoading(false)
    } catch (error) {
      console.error('Erro ao buscar cargos:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao buscar cargos')
      setShowAlert(true)
      setLoading(false)
    }
  }
  useEffect(() => {
    const fetchFuncionarios = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/gerenciarSetores`
        console.log('url', url)
        const dados = {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionarios'
        }

        const funcionariosResponse = await fetchData(url, dados)

        setFuncionarios(funcionariosResponse || [])
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar funcionários:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar funcionários')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchCargos()
    fetchFuncionarios()
  }, [RotaBase, tenantId])

  const handleCriarCargo = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarCargos`
      const criarCargoResponse = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        nome: nomeCargo,
        descricao: descricaoCargo,
        salario_base: salarioBase,
        valor_diaria: valorDiaria,
        valor_hora_extra: valorHoraExtra,
        setorId: setorSelecionado, // Inclui o setorId na requisição
        requisicao: 'criarCargo'
      })
      setCargos(prevCargos => [...prevCargos, criarCargoResponse.cargo])
      setNomeCargo('')
      setDescricaoCargo('')
      setSalarioBase('')
      setValorDiaria('')
      setValorHoraExtra('')
      setSetorSelecionado('')
      setModoEdicao(false)
      setAlertVariant('success')
      setAlertMessage('Cargo criado com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao criar cargo:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao criar cargo')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handleExcluirCargo = async id => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarCargos`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        id,
        requisicao: 'excluirCargo'
      })
      setCargos(prevCargos => prevCargos.filter(c => c._id !== id))
      setAlertVariant('success')
      setAlertMessage('Cargo excluído com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao excluir cargo:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao excluir cargo')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handleEditarCargo = async cargo => {
    setNomeCargo(cargo.nome)
    setDescricaoCargo(cargo.descricao)
    setSalarioBase(cargo.salario_base)
    setValorDiaria(cargo.valor_diaria)
    setValorHoraExtra(cargo.valor_hora_extra)
    setCargoSelecionado(cargo._id)
    setModoEdicao(true)
  }

  const handleAtualizarCargo = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarCargos`
      const atualizarCargoResponse = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        id: cargoSelecionado,
        nome: nomeCargo,
        descricao: descricaoCargo,
        salario_base: salarioBase,
        valor_diaria: valorDiaria,
        valor_hora_extra: valorHoraExtra,
        requisicao: 'atualizarCargo'
      })
      setCargos(prevCargos =>
        prevCargos.map(c => (c._id === cargoSelecionado ? atualizarCargoResponse.cargo : c))
      )
      setNomeCargo('')
      setDescricaoCargo('')
      setSalarioBase('')
      setValorDiaria('')
      setValorHoraExtra('')
      setCargoSelecionado(null)
      setModoEdicao(false)
      setAlertVariant('success')
      setAlertMessage('Cargo atualizado com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao atualizar cargo:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao atualizar cargo')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handleAdicionarFuncionarioAoCargo = async () => {
    setLoading(true)
    console.log('Iniciando a adição de funcionário ao cargo...')
    try {
      const url = `${config.host}/gerenciarCargos`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        cargoId: cargoSelecionado,
        funcionarioId,
        requisicao: 'adicionarFuncionarioAoCargo'
      })

      await fetchCargos()
      setAlertVariant('success')
      setAlertMessage('Funcionário adicionado ao cargo com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao adicionar funcionário ao cargo:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao adicionar funcionário ao cargo')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handleRemoverFuncionarioDoCargo = async (funcionarioId, cargoId) => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarCargos`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        cargoId,
        funcionarioId,
        requisicao: 'removerFuncionarioDoCargo'
      })
      setAlertVariant('success')
      setAlertMessage('Funcionário removido do cargo com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao remover funcionário do cargo:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao remover funcionário do cargo')
      setShowAlert(true)
      setLoading(false)
    }
  }

  return (
    <Container
      fluid
      className="p-4"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ width: '100%', maxWidth: '700px' }}>
        <Banner
          title="Gerenciamento de Cargos"
          width="100%"
          fontSize={47}
          classEfeito3dMouseOver=""
        />
        <Form.Group className="mb-3">
          <h4>Criar Novo Cargo</h4>
          <Form.Control
            as="select"
            value={setorSelecionado}
            onChange={e => setSetorSelecionado(e.target.value)}
          >
            <option value="">Selecione um setor</option>
            {setores.map(setor => (
              <option key={setor._id} value={setor._id}>
                {setor.setor}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            type="text"
            placeholder="Nome do cargo"
            value={nomeCargo}
            onChange={e => setNomeCargo(e.target.value)}
            className="mt-2"
          />
          <Form.Control
            type="text"
            placeholder="Descrição do cargo"
            value={descricaoCargo}
            onChange={e => setDescricaoCargo(e.target.value)}
            className="mt-2"
          />
          <Form.Control
            type="number"
            placeholder="Salário base"
            value={salarioBase}
            onChange={e => setSalarioBase(e.target.value)}
            className="mt-2"
          />
          <Form.Control
            type="number"
            placeholder="Valor da diaria"
            value={valorDiaria}
            onChange={e => setValorDiaria(e.target.value)}
            className="mt-2"
          />
          <Form.Control
            type="number"
            placeholder="Valor por hora extra"
            value={valorHoraExtra}
            onChange={e => setValorHoraExtra(e.target.value)}
            className="mt-2"
          />
          {modoEdicao ? (
            <Button variant="warning" className="mt-2" onClick={handleAtualizarCargo}>
              Atualizar Cargo
            </Button>
          ) : (
            <Button variant="primary" className="mt-2" onClick={handleCriarCargo}>
              Criar Cargo
            </Button>
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <h4>Adicionar Funcionário ao Cargo</h4>
          <Form.Control
            as="select"
            value={setorSelecionado}
            onChange={e => setSetorSelecionado(e.target.value)}
          >
            <option value="">Selecione um setor</option>
            {setores.map(setor => (
              <option key={setor._id} value={setor._id}>
                {setor.setor}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            as="select"
            value={cargoSelecionado}
            onChange={e => setCargoSelecionado(e.target.value)}
            className="mt-2"
          >
            <option value="">Selecione um cargo</option>

            {cargosDoSetor.map(cargo => (
              <option key={cargo._id} value={cargo._id}>
                {cargo.nome}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            as="select"
            value={funcionarioId}
            onChange={e => setFuncionarioId(e.target.value)}
            className="mt-2"
          >
            <option value="">Selecione um funcionário</option>
            {console.log('funcionariosDoSetor:', funcionariosDoSetor)}
            {Array.isArray(funcionariosDoSetor) &&
              funcionariosDoSetor.map(funcionario => (
                <option key={funcionario._id} value={funcionario._id}>
                  {funcionario.Nome}
                </option>
              ))}
          </Form.Control>
          <Button
            variant="primary"
            className="mt-2"
            onClick={handleAdicionarFuncionarioAoCargo}
            disabled={!setorSelecionado || !cargoSelecionado || !funcionarioId} // Desabilitado até que todos os campos estejam preenchidos
          >
            Adicionar Funcionário ao Cargo
          </Button>
        </Form.Group>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Setor</th> {/* Adiciona o cabeçalho de Setor */}
              <th>Cargo</th> {/* Cabeçalho do Cargo */}
              <th>Funcionários</th> {/* Cabeçalho dos Funcionários */}
              <th>Ações</th> {/* Cabeçalho das Ações */}
            </tr>
          </thead>
          <tbody>
            {setores?.map(setor =>
              cargos
                ?.filter(cargo => cargo.setorId === setor._id) // Filtra os cargos pelo setor correspondente
                .map(cargo => (
                  <tr key={cargo._id}>
                    <td>{setor.setor}</td> {/* Exibe o nome do Setor */}
                    <td>{cargo.nome}</td> {/* Exibe o nome do Cargo */}
                    <td>
                      {/* Encontra o setor correspondente e exibe os nomes dos funcionários */}
                      {setor.funcionarios && setor.funcionarios.length > 0
                        ? setor.funcionarios
                            .filter(func => cargo.funcionarios.includes(func._id))
                            .map(func => func.Nome)
                            .join(', ')
                        : 'Nenhum funcionário'}
                    </td>
                    <td style={{ width: '138px' }}>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => handleEditarCargo(cargo)}
                        style={{ marginRight: '5px' }}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleExcluirCargo(cargo._id)}
                      >
                        Excluir
                      </Button>
                    </td>
                  </tr>
                ))
            )}
          </tbody>
        </Table>
      </div>

      {loading && <Spinner animation="border" />}

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default GerenciamentoCargos
