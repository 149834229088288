import { useState, useEffect, useContext } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'
import CadastroFacial from '../../../../../src/components/pages/RELOGIODEPONTO/FuncionariosAtivos/CadastroFacial/cadastro-facial.jsx' // Ajuste o caminho conforme necessário
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../context/AuthContext.jsx'
const JustificativaModal = ({ show, onHide, onSave, funcionarios, justificativaSelecionada }) => {
  const [motivo, setMotivo] = useState('')
  const [data, setData] = useState('')
  const [status, setStatus] = useState('')
  const [funcionario, setFuncionario] = useState(null)
  const [documento, setDocumento] = useState(null)

  // Estados para autenticação facial
  const [autenticadoGerente, setAutenticadoGerente] = useState(false)
  const [mostrarModalFace, setMostrarModalFace] = useState(false)
  const { tenantId } = useContext(AuthContext)
  useEffect(() => {
    if (justificativaSelecionada) {
      setMotivo(justificativaSelecionada.motivo || '')
      setData(justificativaSelecionada.data || '')
      setStatus(justificativaSelecionada.status || 'Pendente')
      setFuncionario(
        funcionarios.find(func => func.value === justificativaSelecionada.funcionarioId._id) || null
      )
      setDocumento(null)
    } else {
      setMotivo('')
      setData('')
      setStatus('Pendente')
      setFuncionario(null)
      setDocumento(null)
    }
  }, [justificativaSelecionada, funcionarios])

  const handleSave = () => {
    if (!funcionario && !justificativaSelecionada) {
      alert('Por favor, selecione um funcionário.')
      return
    }
    if (!data) {
      alert('Por favor, selecione uma data.')
      return
    }

    // Verifica se a alteração do status requer autenticação e se já foi autenticado
    if (justificativaSelecionada && !autenticadoGerente) {
      alert('Para alterar o status, autentique-se como Gerente de Operações.')
      return
    }

    const justificativa = {
      id: justificativaSelecionada?.id || null,
      motivo,
      data,
      status,
      funcionarioId: funcionario.value,
      documento
    }

    onSave(justificativa)
    // Após salvar, bloqueia novamente o acesso ao select de status
    setAutenticadoGerente(false) // Reseta a autenticação
  }

  const handleHide = () => {
    setAutenticadoGerente(false) // Reseta a autenticação
    onHide() // Fecha o modal
  }
  return (
    <>
      <Modal show={show} onHide={handleHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {justificativaSelecionada ? 'Editar Justificativa' : 'Nova Justificativa'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="funcionario">
              <Form.Label>Funcionário</Form.Label>
              <Select
                options={funcionarios}
                value={
                  funcionarios.find(
                    func => func.value === justificativaSelecionada?.funcionarioId?._id
                  ) || null
                }
                onChange={setFuncionario}
                isDisabled={!!justificativaSelecionada}
                placeholder="Selecione um funcionário"
              />
            </Form.Group>

            <Form.Group controlId="data">
              <Form.Label>Data</Form.Label>
              <Form.Control
                type="date"
                value={data ? new Date(data).toISOString().split('T')[0] : ''}
                onChange={e => setData(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="motivo">
              <Form.Label>Motivo</Form.Label>
              <Form.Control
                type="text"
                value={motivo}
                onChange={e => setMotivo(e.target.value)}
                placeholder="Digite o motivo"
              />
            </Form.Group>

            <Form.Group controlId="status">
              <Form.Label>Status</Form.Label>
              {/* Se ainda não autenticado, exibe botão para autenticar */}
              {!autenticadoGerente ? (
                <>
                  <p>Para editar o status, autentique-se como Gerente de Operações.</p>
                  <Button variant="warning" onClick={() => setMostrarModalFace(true)}>
                    Autenticar Gerente
                  </Button>
                </>
              ) : (
                <Form.Control as="select" value={status} onChange={e => setStatus(e.target.value)}>
                  <option value="">Selecione o status</option>
                  <option value="pendente">Pendente</option>
                  <option value="aprovado">Aprovado</option>
                  <option value="recusado">Recusado</option>
                </Form.Control>
              )}
            </Form.Group>

            <Form.Group controlId="documento">
              <Form.Label>Documento</Form.Label>
              <Form.Control type="file" onChange={e => setDocumento(e.target.files[0])} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Autenticação Facial */}
      {mostrarModalFace && (
        <Modal show={mostrarModalFace} onHide={() => setMostrarModalFace(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Autenticação Facial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CadastroFacial
              modoAutenticacao={true}
              onFaceCadastrada={async colaborador => {
                try {
                  const url = `${config.host}/gerenciarGerentesOperacao`
                  const response = await fetchData(url, {
                    tenantId,
                    requisicao: 'verificarPermissaoGerente',
                    colaboradorId: colaborador.funcionarioId
                  })
                  console.log('response.eGerente', response.eGerente)
                  console.log('colaborador', colaborador)
                  console.log('tenantId', tenantId)
                  if (response.eGerente) {
                    setAutenticadoGerente(true)
                  } else {
                    alert('Usuário não possui permissão de Gerente de Operações.')
                  }
                } catch (error) {
                  console.error('Erro na verificação de permissão:', error)
                }
                setMostrarModalFace(false)
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

JustificativaModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  funcionarios: PropTypes.array.isRequired,
  justificativaSelecionada: PropTypes.object
}

export default JustificativaModal
